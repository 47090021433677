import React from "react";
import { Container, Row } from "react-bootstrap";

function ResponsiblePlay() {
  return (
    <>
      <section className="resonsible aboutpage">
        <Container>
          <Row>
            <div className="col-md-12">
              <div className="header-about">
                <h1 className="mainheading">Responsible Play</h1>
              </div>
            </div>
            <Row className=" justify-content-center">
              <div className="col-md-10">
                <div className="content">
                  <div>
                    <h3><strong>What is dzire11?</strong></h3>
                    <hr />
                    <p className="">
                      dzire11 is one of the leading online fantasy sport game
                      to play online cricket leagues. Users can make their own
                      team according to their sports knowledge and win cash.
                    </p>
                    <h3><strong>How do I sign up?</strong></h3>
                    <hr />
                    <p className="">
                      The users can sign up on dzire11 by filling a short
                      registration form available on the homepage of dzire11
                      website. However, the users can also signup directly by
                      using their Facebook or Google+ account.
                    </p>
                    <h3><strong> Why can’t I log in to my account?</strong></h3>
                    <hr />
                    <p className="">
                      The users may encounter signup problems due to various
                      reasons. The primary reasons due to which the user might
                      not be able to signup can include the user might be
                      entering incorrect email address or password. In case the
                      user has forgotten his/her password, there’s a Forgot
                      Password link available below the login option. By
                      clicking on the Forgot Password link, the user will be
                      taken to a new webpage, wherein the user can reset his/her
                      password by entering his/her registered mobile number and
                      dzire11 will send an OTP. The user can enter the OTP
                      sent by dzire11 to reset his/her password.
                    </p>
                    <h3><strong>Can I have multiple accounts on dzire11?</strong></h3>
                    <hr />
                    <p className="">
                      No. A user is allowed to have only one account under its
                      name with valid supporting documents on dzire11. Please
                      refer Fair Play policy for further details.
                    </p>
                    <h3><strong> Can I update/edit my information?</strong></h3>
                    <hr />
                    <p className="">
                      The users can easily update/edit their information by
                      logging into their dzire11 accounts using their
                      registered email address and password. After logging into
                      their dzire11 account the users can edit/update their
                      information by clicking on ‘Edit Profile’ on the top right
                      of the page. The users will be able to edit their basic
                      information including date of birth, profile name and
                      address but the users won’t be allowed to change their
                      email address, mobile number or team name once registered
                      with dzire11.
                    </p>
                    <h3><strong>I did not get any confirmation email after I signed up.</strong></h3>
                    <hr />
                    <p className="">
                      <br />
                    </p>
                    <p className="">
                      In case you haven’t received any confirmation email from
                      dzire11 after registering with dzire11 there could
                      be 2 reasons:
                    </p>
                    <p className="">
                      The mail sent by dzire11 might have been marked as
                      “Spam” and could have been sent to the spams folder of
                      your mailbox. In such case, please check the spam folder
                      of your mailbox and mark dzire11 as ‘Not Spam,’
                      additionally add dzire11 to your contacts in order to
                      avoid any future inconvenience.
                    </p>
                    <p className="">
                      <br />
                    </p>
                    <p className="">
                      The user might have mistyped his/her email address while
                      registering with dzire11. In such case, please reach
                      out to us via our Contact Us page.
                    </p>
                    <p className="">
                      <br />
                    </p>
                    <h3><strong> I don’t know much about cricket – can I still play?</strong></h3>
                    <hr />
                    <p className="">
                      The online fantasy cricket gaming services offered by
                      dzire11 are for everyone including those who don’t know
                      much about cricket. So, yes, you can play even if you
                      don’t know much about cricket. In addition, the “How to
                      play” section of dzire11 website can assist you in
                      playing the game by briefing you about the playing
                      tactics.
                    </p>
                    <p>
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </Row>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default ResponsiblePlay;
