import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { getAirPayPayload, sendToAirPay } from "../api/form.service";
import { useRef } from "react";

function Airpay() {
  const [html, setHtml] = useState(``)
  const [params, setParams] = useSearchParams()
  const [payload, setPayload] = useState({})

  const formRef = useRef()

  useEffect(() => {
    const token = params.get("token")
    const amount = params.get("amount")
    const coupon = params.get("coupon")
    console.log('token: ', token);
    console.log('amount: ', amount);

    getAirPayPayload((_payload) => {
      console.log('payload: ', _payload);
      setPayload(_payload?.data);


      setTimeout(function () {
        document.getElementById("sendtoairpay").submit();
      }, 1000 * 1);


    }, { token, amount, coupon })


  }, [])






  return (
    <>
      {/* <div
        dangerouslySetInnerHTML={{
          __html: html
        }}
      /> */}


      <div class="main container">
        <div class="row">
          <div class="col-md-12">
            <table width="100%">
              <tr>
                <td align="center" valign="middle">
                  <h1 class="display-4 m-b-2">Do Not Refresh or Press Back <br /> Redirecting to Airpay</h1>
                </td>
              </tr>
            </table>
            <form method="POST" action="https://payments.airpay.co.in/pay/index.php" id="sendtoairpay" >
              {
                Object.keys(payload).length ?

                  Object.keys(payload).map((key) => {
                    return (
                      <div class="form-group" key={key} >
                        <input class="form-control" id={key} type="hidden" name={key} value={payload[key]} />
                      </div>
                    )
                  })

                  : <></>
              }

            </form>
          </div>
        </div>
      </div>

    </>
  );
}

export default Airpay;
