import {CopyToClipboard} from "react-copy-to-clipboard";
import React, { useEffect, useState } from "react";
import Qr from "../../assets/Qr.jpg";
import { FaRegCopy } from "react-icons/fa";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import youtube_icon from '../../assets/youtube.svg'
import axios from "axios";
const env = require("../../config/env");
console.log('env: ', env);

const youtubeLink = "https://www.youtube.com/watch?v=EhVBNwgDJ0A"

const Payment = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  let [code, setCode] = useState("dzire11@ybl");
  const [upi_transaction_id, setupi_transaction_id] = useState("")

  

  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(code);
    toast("Copied to Clipboard!", {
      icon: "🔗",
      style: {
        borderRadius: "30px",
        background: "#0380f5",
        color: "#fff",
      },
    });
  };
  const apiToast = (e) => {
    navigator.clipboard.writeText(code);
    toast("Please Enter 12 Digit UTR No", {
      icon: "⚠",
      style: {
        borderRadius: "30px",
        background: "#00610E",
        color: "#fff",
      },
    });
  };

  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }


  const submit = async () => {
    try {
      if (!upi_transaction_id || !searchParams.get("orderId")) {
        return apiToast()
      }
      console.log("CALLING POST API")
      // axios.defaults.baseURL = "http://13.233.51.81"
      const response = await axios.post(`/wallet/add-manual-web`, { orderId: searchParams.get("orderId"), upi_transaction_id: upi_transaction_id }, {
        headers: {
          authorization: "Bearer " + searchParams.get("token")
        }
      });
      console.log('response?.data: ', response?.data);
      if (response?.data?.status) {
        // window.close();
        toast.success("Your wallet will get update in some time");
        await sleep(2000)
        navigate('/payment-done')
        return true;

      } else {
        toast.error(response?.data?.message);
        await sleep(2000)
        navigate('/payment-done')

        return false;
      }
    } catch (e) {
      console.error('e: ', e);

    }
  };
  const back = () => {
    navigate('/payment-done')
  };
  return (
    <main>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="payment">
        <div className="mobile-blank-header"></div>
        <div className="mobile-header">
          <h6 className="mx-auto">Amount:{searchParams.get("amount")}</h6>
          <h6 className="mx-auto">Ord#:{searchParams.get("orderId")}</h6>
        </div>
        <div className="mobile-main">
          <div className="note">
            <a href={youtubeLink} target="_blank">
              <div className="youtube_link">
                <img src={youtube_icon} alt="#" className="img-fluid" />
                <h5>How to deposit ?</h5>
              </div>
            </a>
          </div>
          {/* <p className="watchVideo">Watch Video How to deposit?</p> */}
          <div className="Qr">
            <p>Scan Qr Code below with your PSP App</p>
            <img src={Qr} alt="#" />
            <p> Or Pay to VPA </p>
            <div className="code">
                <button className="upi_id">{code}</button>
              <CopyToClipboard text={code} onCopy={copyToClipboard}>
              <button  onCopy={copyToClipboard} className="copy_btn">
                <FaRegCopy size={20} />
              </button>
              </CopyToClipboard>
            </div>
          </div>
          <p className="bankref">Bank Ref. No (Mandatory):</p>
          <input className="utr" placeholder="Enter 12 Digit UTR No" onChange={e => setupi_transaction_id(e.target.value)} value={upi_transaction_id} />
          <div className="btns">

            <button className="submit" onClick={submit}>
              Submit
            </button>
            <button className="back" onClick={back}>
              Back
            </button>
          </div>
        </div>
        <div className="mobile-blank-footer"></div>
      </div>
    </main>
  );
};

export default Payment;