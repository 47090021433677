import React from "react";

function disclaimer() {
  return (
    <>
      <section className="disclaimer aboutpage">
        <div className="container">
          <div class="row">
            <div class="col-md-12">
              <div class="header-privacy">
                <h1 class="text-center">
                  <strong>Disclaimer</strong>
                </h1>
                <p>
                  The following disclaimer outlines the terms of using Dzire11,
                  a fantasy sports platform you can’t miss! Before engaging in
                  any activities on our website or mobile application, please
                  read and understand the following points:
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="content pb-4">
                <h3>
                  <strong>1. Entertainment Purposes: </strong>
                </h3>
                <hr />
                <p>
                  Dzire11 is designed solely for entertainment purposes and should not be considered a financial investment platform. While users can earn real cash prizes, participation in fantasy sports involves a level of risk, and users should be aware that they may lose the money they invest.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="content pb-4">
                <h3>
                  <strong>2.	Performance Variation: </strong>
                </h3>
                <hr />
                <p>
                The performance of players and teams in real-life sports events may vary, and fantasy teams' success depends on these real-life performances. Dzire11 does not guarantee any specific results or outcomes in the leagues.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="content pb-4">
                <h3>
                  <strong>3.	User Responsibility:  </strong>
                </h3>
                <hr />
                <p>
                Users are solely responsible for their own decisions and actions while participating in Dzire11 leagues. It is advised to exercise caution, conduct research, and make informed choices when selecting players and forming fantasy teams.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="content pb-4">
                <h3>
                  <strong>4.	Rule Changes: </strong>
                </h3>
                <hr />
                <p>
                Dzire11 reserves the right to change the rules, scoring systems, and prize distribution without prior notice. Users are encouraged to review the updated terms and conditions regularly to stay informed about any modifications.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="content pb-4">
                <h3>
                  <strong>5.	Technical Issues: </strong>
                </h3>
                <hr />
                <p>
                While Dzire11 strives to provide a seamless experience, we do not take responsibility for any technical issues, errors, or interruptions that may occur while using the platform. However, we are committed to promptly addressing and resolving any issues that may arise.


                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="content pb-4">
                <h3>
                  <strong>6.	Legality: </strong>
                </h3>
                <hr />
                <p>
                While fantasy sports are legal in many jurisdictions, the user must ensure compliance with local laws and regulations. Dzire11 does not guarantee the legality of its services in every jurisdiction, and users should refrain from accessing the platform if their local laws prohibit participation in fantasy sports.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="content pb-4">
                <h3>
                  <strong>7.	Intellectual Property: </strong>
                </h3>
                <hr />
                <p>
                Dzire11 and its associated trademarks, logos, and content are the intellectual property of Dzire11. Users are prohibited from using, copying, reproducing, modifying, or distributing any of the platform's intellectual property without written consent.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="content pb-4">
                <h3>
                  <strong>8.	Fraudulent Activities: </strong>
                </h3>
                <hr />
                <p>
                Dzire11 has a zero-tolerance policy for fraudulent activities, cheating, or violating the platform's terms and conditions. Users engaged in such activities may be disqualified, and their accounts may be terminated, leading to the forfeiture of any winnings.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="content pb-4">
                <h3>
                  <strong>9.	Third-Party Links: </strong>
                </h3>
                <hr />
                <p>
                Dzire11 is designed solely for entertainment purposes and should not be considered a financial investment platform. While users can earn real cash prizes, participation in fantasy sports involves a level of risk, and users should be aware that they may lose the money they invest.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="content pb-4">
                <h3>
                  <strong>10.	Termination: </strong>
                </h3>
                <hr />
                <p>
                Dzire11 reserves the right to suspend or terminate user accounts, leagues, or the entire platform at its sole discretion. Dzire11 may do so without prior notice if users violate the terms and conditions, engage in fraudulent activities, or pose a threat to the integrity of the platform.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default disclaimer;
