import React from "react";
import airpaylogo from "../../src/assets/airpay.svg";

function AirpayResponse() {
  return (
    <>
      <section className="airpay_sec">
        <div className="container">
          <div className="row ">
            <div className="col-md-12  d-flex justify-content-center mt-5">
              <div className="text-center">
                <img
                  src={airpaylogo}
                  alt={airpaylogo}
                  width={220}
                  className="img-fluid"
                />
                <h4 className="d-block  text-black mt-3">Please Check Your Wallet Now</h4>
                <p><span style={{color: 'red'}}>Note:-</span> If Not Found In Any Case Restart Your App And Check.</p>
                {/* <button className="btn btn-success mt-2 close_btn" onClick={
                  window.close}>Close</button> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AirpayResponse;
