import React from 'react';
import { Container, Row, Col, Accordion } from "react-bootstrap";

const faq__cricket = () => {
  return (

    <section className="faq__section py-5 faq__section_sub">
      <Container>
        <Row>
          <Col>
            <div className="section__title__area pt-5">
              <h2>FREQUENTLY ASKED QUESTIONS</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} className="p-md-2 p-lg-5">
            <Accordion >
              <Accordion.Item eventKey="0">
                <Accordion.Header>What is fantasy cricket?</Accordion.Header>
                <Accordion.Body>
                  In fantasy cricket, users draught a squad of real-life cricketers and earn points depending on
                  their team's statistical performance in simulated games. Players can compete in daily, weekly, or
                  season-long tournaments against one another.

                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>Can I create my own fantasy cricket league on Dzire11?</Accordion.Header>
                <Accordion.Body>
                  The answer is yes; with Dzire11, players may make their own fantasy cricket leagues. Visit the
                  "Leagues" area of the site and then select the "Create a League" option to begin the process of
                  making a new league. The settings for the league, such as the entry price, the number of
                  players, and the scoring system, are all editable from this page.

                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>Can I change my fantasy cricket team after the match starts on Dzire11?</Accordion.Header>
                <Accordion.Body>
                  In most cases, once the match has begun on Dzire11, you will no longer be able to change your
                  fantasy cricket team. However, if a player is sick or injured, you may be permitted to substitute in
                  some leagues. If you need additional information, look up your league's regulations.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>How do I score points in fantasy cricket?</Accordion.Header>
                <Accordion.Body>
                  Your fantasy team's point total is determined by how its members perform in actual games.
                  Runs scored by a batsman and wickets taken by a bowler are two examples of how players can
                  earn points. If your team's captain and vice-captain can pull through with impressive displays,
                  your team's overall score will rise.

                </Accordion.Body>
              </Accordion.Item>

            </Accordion>
          </Col>
          <Col xs={12} md={6} className="p-md-2 p-lg-5">
            <Accordion >
              <Accordion.Item eventKey="4">
                <Accordion.Header> Can I view the standings and results of my fantasy cricket league on Dzire11?</Accordion.Header>
                <Accordion.Body>
                  Dzire11 does, in fact, provide a standings board where you can check how you and your
                  teammates fared in your fantasy cricket league. The standings are available after selecting your
                  league under the "Leagues" tab.

                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="5">
                <Accordion.Header>How do I withdraw my winnings from fantasy cricket on Dzire11?</Accordion.Header>
                <Accordion.Body>
                  You can cash out your Dzire11 fantasy cricket earnings by going to the "My Account" page and
                  clicking the "Withdraw" option. From that point on, all you have to do to get your money is select
                  the amount you wish to withdraw and then follow the on-screen instructions.

                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="6">
                <Accordion.Header>Are there any rules or regulations for playing fantasy cricket?</Accordion.Header>
                <Accordion.Body>
                  The guidelines for playing Fantasy Cricket could vary from one platform to the next. Before
                  entering a contest, be sure you understand the rules. Users are generally tasked with abiding by
                  the platform's rules and T&Cs.


                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="7">
                <Accordion.Header>Is fantasy cricket legal?</Accordion.Header>
                <Accordion.Body>
                  Most countries view fantasy cricket as a legitimate sport. However, before engaging in any form
                  of online gaming or betting, it is always a good idea to examine the laws and regulations in your
                  unique country.
                </Accordion.Body>
              </Accordion.Item>

            </Accordion>
          </Col>
        </Row>
      </Container>
    </section>

  )
}

export default faq__cricket