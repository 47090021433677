import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Legality() {
  return (
    <>
      <section className="legality_sec aboutpage">
        <Container>
          <Row>
            <div className="col-md-12">
              <div className="header-about">
                <h1 className="mainheading">Legality</h1>
                <h1>
                  <strong>
                    The <span className="red-text">The legality of </span> Game
                    of Skill
                  </strong>
                </h1>
                <p>
                  Fantasy sports contests are considered games of skill in most
                  jurisdictions. However, the legality of participating in
                  fantasy sports contests may vary from state to state and
                  country to country. Your responsibility is to ensure that
                  participation in fantasy sports contests is legal in your
                  jurisdiction.
                </p>
              </div>
              <div className="leaglity_content header-about">
                <h3>Eligibility</h3>
                <hr/ >
                <p>
                  To be eligible to participate in a contest or to win a prize
                  on the dzire11 website, you must meet the following
                  requirements:
                </p>
                <ul>
                  <li>You must be at least 18 years of age</li>
                  <li>
                    You must be a legal resident of the country in which you are
                    accessing the dzire11
                  </li>
                  <li>
                    You must not be a resident of a jurisdiction where
                    participation in fantasy sports contests is prohibited by
                    law.
                  </li>
                  <li>
                    You must not be an{" "}
                    <b> employee of dzire11 or any of its affiliates.</b>
                  </li>
                  <li>
                    You must not be an <b> immediate family member </b>of an
                    employee of dzire11 or any of its affiliates.
                  </li>
                </ul>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Legality;
