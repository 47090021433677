import React from "react";
import cta_mockup_desktop from "../../assets/mobile_mockup_desktop.png";
import ios_button from "../../assets/ios__button.png";
import android__button from "../../assets/android__button.webp";
import { Container, Row, Col } from "react-bootstrap";

const cta__football = () => {
  return (
    <section className="cta__download cta__football">
      <Container>
        <Row>
          <Col
            xs={12}
            md={6}
            lg={5}
            className="d-flex flex-column justify-content-center align-items-center align-items-md-start "
          >
            <h3>
              a super <span className="red-text">welcome offer!</span>
            </h3>
            <h4>download & sign-up to get</h4>
            <h3>
              <span className="red-text">300₹</span> and referral bonus <br /> as{" "}
              <span className="red-text">500₹ </span> 
              in  your cash <span className="red-text">bonus</span>
            </h3>
            <div className="d-flex text-left mt-3 flex-column flex-md-row">
              <a href="/">
                <img
                  className="CTA__bownload__btn ios__button"
                  src={ios_button}
                  alt="iOS App Download"
                />
              </a>{" "}
              <br />
              <a href="/">
                <img
                  className="CTA__bownload__btn android_button"
                  src={android__button}
                  alt="Android App Download"
                />
              </a>
            </div>
          </Col>

          <Col
            xs={12}
            md={6}
            lg={7}
            className="d-flex justify-content-center justify-content-md-end"
          >
            <img
              className="cta_mockup_desktop"
              src={cta_mockup_desktop}
              alt=""
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default cta__football;
