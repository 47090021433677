import React from "react";

import { CTAF } from "../components";
import { FAQfootball } from "../components";

import { Container, Row, Col } from "react-bootstrap";
import football_main from '../assets/football-main.png';
import football_mobile from '../assets/football_mobile.png';

import step__1__img from '../assets/step__1.png';
import step__2__img from '../assets/step__2.png';
import step__3__img from '../assets/step__3.png';
import step__4__img from '../assets/step__4.png';
import step__5__img from '../assets/step__5.png';

function Football() {
  return (

    <>
      <section className="cricket_sec" >
        <Container>
          <Row className="py-5">
            <Col className="text-center">
              <h1> <strong>Fantasy Football Playing Guide</strong> </h1>
            </Col>
          </Row>

          {/* Desktop Layout*/}

          <Row className="pb-5 d-none d-lg-flex">
            <Col xs={12} md={5} lg={6} className="d-flex justify-content-center align-items-center">
              <img className="img-fluid py-5" src={football_main} alt="" />
            </Col>
            <Col xs={12} md={7} lg={6} className="d-flex flex-column justify-content-center align-items-center">

              <div className="step__box step__1_box">
                <h4>Find a league</h4>
                <p>You can join an existing or new league or start your own with your friends or coworkers.</p>
              </div>
              <div className="step__box step__2_box">
                <h4>Draft your team</h4>
                <p>Draft day is when teams choose which players will join their squad. So, choose accordingly!</p>
              </div>
              <div className="step__box step__3_box">
                <h4>Choose Captain and Vice Captain</h4>
                <p>Earn more points by choosing the right captain and vice-captain! It’s the real deal, so be careful!</p>
              </div>
              <div className="step__box step__4_box">
                <h4>Join Contest</h4>
                <p>Now, it’s time to join a contest and make a storm! Join a contest, and let’s play!</p>
              </div>
              <div className="step__box step__5_box">
                <h4>Keep up with the scores</h4>
                <p>You can keep tabs on your progress and see how you stack up against the competition with the help of these standings. The winner will be declared based on the highest point gained!</p>
              </div>

            </Col>
          </Row>

          {/* Mobile Layout */}
          <div className="d-block d-lg-none pb-5">
            <Row>
              <Col className="text-center pb-5">
                <img src={football_mobile} alt="" />
              </Col>
            </Row>

            <Row className="step__box_item">
              <Col xs={2} sm={2} className="d-flex justify-content-center align-items-center">
                <img src={step__1__img} alt="" />
              </Col>
              <Col xs={10} sm={10}>
                <div className="step__box step__1_box">
                  <h4>Find a league</h4>
                  <p>You can join an existing or new league or start your own with your friends or coworkers.</p>
                </div>
              </Col>
            </Row>

            <Row className="step__box_item">
              <Col xs={2} sm={2} className="d-flex justify-content-center align-items-center">
                <img src={step__2__img} alt="" />
              </Col>
              <Col xs={10} sm={10}>
                <div className="step__box step__2_box">
                  <h4>Draft your team</h4>
                  <p>Draft day is when teams choose which players will join their squad. So, choose accordingly</p>
                </div>
              </Col>
            </Row>

            <Row className="step__box_item">
              <Col xs={2} sm={2} className="d-flex justify-content-center align-items-center">
                <img src={step__3__img} alt="" />
              </Col>
              <Col xs={10} sm={10}>
                <div className="step__box step__3_box">
                  <h4>Choose Captain and Vice Captain</h4>
                  <p>Earn more points by choosing the right captain and vice-captain! It’s the real deal, so be careful!</p>
                </div>
              </Col>
            </Row>

            <Row className="step__box_item">
              <Col xs={2} sm={2} className="d-flex justify-content-center align-items-center">
                <img src={step__4__img} alt="" />
              </Col>
              <Col xs={10} sm={10}>
                <div className="step__box step__4_box">
                  <h4>Join Contest</h4>
                  <p>Now, it’s time to join a contest and make a storm! Join a contest, and let’s play!</p>
                </div>
              </Col>
            </Row>

            <Row className="step__box_item">
              <Col xs={2} sm={2} className="d-flex justify-content-center align-items-center">
                <img src={step__5__img} alt="" />
              </Col>
              <Col xs={10} sm={10}>
                <div className="step__box step__5_box">
                  <h4>Keep up with the scores</h4>
                  <p>You can keep tabs on your progress and see how you stack up against the competition with the help of these standings. The winner will be declared based on the highest point gained!</p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>

      <section className="tips__section">
        <Container>
          <Row className="mb-md-5 mb-4 text-center text-md-start">
            <Col>
              <h2>Fantasy Football <span className="red-text">Tips</span></h2>

            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <div className="single__tip">
                <p>You should research the athletes you are thinking about drafting before the draught.
                  Expert rankings and projections from fantasy leagues can also be consulted to
                  understand how a player is likely to fare.</p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="single__tip">
                <p>Make sure your team is well-rounded. If you want to win at fantasy football, you need a
                  balanced team, not just a bunch of superstars.</p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="single__tip">
                <p>Set your lineup each week! Set your lineup for the forthcoming games each week. To
                  win, you must put your best players on the field.</p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="single__tip">
                <p>Your team's defence and special teams can't be ignored. Although it's natural to give
                  most of your attention to the offence, keeping an eye on the defence and special teams
                  is just as crucial. The performance of these sections can often make or break your
                  squad.
                </p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="single__tip">
                <p>You can't judge a team's performance in only one week. Don't make significant
                  adjustments to your lineup based on a single week's performance; it's usual for players
                  to have ups and downs during the season.</p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="single__tip">
                <p>Keep in mind the bye weeks! During an NFL season, each team has a bye week in
                  which they do not have to field a team. Keep track of your team's bye weeks so you
                  know who to call from the bench or the waiver wire to fill in for them.
                </p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="single__tip">
                <p>Make strategic trades! Making trades can help your fantasy football team immensely. If
                  you have a struggling or injured players, keep an eye out for potential trades that could
                  improve your team.</p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="single__tip">
                <p>Use the waiver wire wisely. A player who is not currently on the roster of any team can
                  be claimed off of the waiver wire and added to a team's roster. However, you shouldn't
                  use all of your waiver priority on a guy who might not be worth the loss of a good waiver
                  choice.
                </p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="single__tip">
                <p>Keep up-to-date. It's crucial to keep up with the newest news and updates in the NFL, as
                  roster changes due to injuries or other circumstances can occur quickly</p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="single__tip">
                <p>Have a good time! The National Football League is a lot of fun, and fantasy football is a
                  terrific way to keep up with the action while you're at it.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <CTAF />
      <FAQfootball />
    </>

  );
}

export default Football;