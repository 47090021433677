import React from "react";

import { CTA } from "../components";
import { FAQcricket } from "../components";

import { Container, Row, Col } from "react-bootstrap";
import cricket__mainimag from '../assets/cricket-main.png';
import cricket__mobile from '../assets/cricket__mobile.png';

import step__1__img from '../assets/step__1.png';
import step__2__img from '../assets/step__2.png';
import step__3__img from '../assets/step__3.png';
import step__4__img from '../assets/step__4.png';
import step__5__img from '../assets/step__5.png';

function Cricket() {
  return (

    <>
      <section className="cricket_sec">
        <Container>
          <Row className="py-5">
            <Col className="text-center">
              <h1> <strong>Fantasy Cricket Playing Guide</strong> </h1>
            </Col>
          </Row>

          {/* Desktop Layout*/}

          <Row className="pb-5 d-none d-lg-flex">
            <Col xs={12} md={5} lg={6} className="d-flex justify-content-center align-items-center">
              <img className="img-fluid py-5" src={cricket__mainimag} alt="" />
            </Col>
            <Col xs={12} md={7} lg={6} className="d-flex flex-column justify-content-center align-items-center">

              <div className="step__box step__1_box">
                <h4>Select a Match</h4>
                <p>Pick out a cricket league and a match to attend. We have several options for you to consider if
                  you're looking for a league where you can show off your skills and expertise.</p>
              </div>
              <div className="step__box step__2_box">
                <h4>Create a Team</h4>
                <p>The draught is now in progress. Use your cricket knowledge to good by picking the right players
                  and forming a formidable squad because Fortune Favors the Bold!
                </p>
              </div>
              <div className="step__box step__3_box">
                <h4>Choose Captain and Vice Captain</h4>
                <p>As you draft your team, pay special attention to the captain and vice-captain positions. Picking a
                  captain and vice-captain can be a game-changer, so make sure you do it.
                </p>
              </div>
              <div className="step__box step__4_box">
                <h4>Choose a Contest</h4>
                <p>Once you have a squad assembled, you can enter a competition and begin competing. Let’s
                  face off against other users and score points based on your team's performance.</p>
              </div>
              <div className="step__box step__5_box">
                <h4>Track Scores and Winning
                </h4>
                <p>Keep track of your team's standing during the season so you may make adjustments to boost
                  your chances of victory. Our website lets you keep tabs on your progress in real-time!</p>
              </div>

            </Col>
          </Row>

          {/* Mobile Layout */}
          <div className="d-block d-lg-none pb-5">
            <Row>
              <Col className="text-center pb-5">
                <img src={cricket__mobile} alt="" />
              </Col>
            </Row>

            <Row className="step__box_item">
              <Col xs={2} sm={2} className="d-flex justify-content-center align-items-center">
                <img src={step__1__img} alt="" />
              </Col>
              <Col xs={10} sm={10}>
                <div className="step__box step__1_box">
                  <h4>Select a Match</h4>
                  <p>Pick out a cricket league and a match to attend. We have several options for you to consider if
                    you're looking for a league where you can show off your skills and expertise.</p>
                </div>
              </Col>
            </Row>

            <Row className="step__box_item">
              <Col xs={2} sm={2} className="d-flex justify-content-center align-items-center">
                <img src={step__2__img} alt="" />
              </Col>
              <Col xs={10} sm={10}>
                <div className="step__box step__2_box">
                  <h4>Create a Team</h4>
                  <p>The draught is now in progress. Use your cricket knowledge to good by picking the right players
                    and forming a formidable squad because Fortune Favors the Bold!</p>
                </div>
              </Col>
            </Row>

            <Row className="step__box_item">
              <Col xs={2} sm={2} className="d-flex justify-content-center align-items-center">
                <img src={step__3__img} alt="" />
              </Col>
              <Col xs={10} sm={10}>
                <div className="step__box step__3_box">
                  <h4>Choose Captain and Vice Captain</h4>
                  <p>As you draft your team, pay special attention to the captain and vice-captain positions. Picking a
                    captain and vice-captain can be a game-changer, so make sure you do it.</p>
                </div>
              </Col>
            </Row>

            <Row className="step__box_item">
              <Col xs={2} sm={2} className="d-flex justify-content-center align-items-center">
                <img src={step__4__img} alt="" />
              </Col>
              <Col xs={10} sm={10}>
                <div className="step__box step__4_box">
                  <h4>Choose a Contest</h4>
                  <p>Once you have a squad assembled, you can enter a competition and begin competing. Let’s
                    face off against other users and score points based on your team's performance.</p>
                </div>
              </Col>
            </Row>

            <Row className="step__box_item">
              <Col xs={2} sm={2} className="d-flex justify-content-center align-items-center">
                <img src={step__5__img} alt="" />
              </Col>
              <Col xs={10} sm={10}>
                <div className="step__box step__5_box">
                  <h4>Track Scores and Winning</h4>
                  <p>Keep track of your team's standing during the season so you may make adjustments to boost
                    your chances of victory. Our website lets you keep tabs on your progress in real-time!</p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>

      <section className="tips__section">
        <Container>
          <Row className="mb-md-5 mb-4 text-center text-md-start">
            <Col>
              <h2>Fantasy Cricket <span className="red-text">Tips</span></h2>

            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <div className="single__tip">
                <p>Analyze and investigate the performance of players. Examine each player's record and current form to forecast how they would fare in the upcoming game</p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="single__tip">
                <p>Take note of the state of the pitch. It's important to note that the pitch and weather conditions at a match can significantly affect the outcome. As you choose your team, keep in mind the state of the field.</p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="single__tip">
                <p>Think about the starting lineup! Before finalising your fantasy team, you should verify thatall the players you've chosen are expected to start for the actual team.</p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="single__tip">
                <p>Keep up with the latest team news and any injuries! Keep yourself apprised of any news
                  affecting your fantasy team, such as player injuries.</p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="single__tip">
                <p>Risk-taking is essential. Be bold and take a chance on a player who you believe can
                  perform well. Taking a chance on a selection can pay off handsomely at times.</p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="single__tip">
                <p>Keep the captain and vice-captain in mind. Extra points will be awarded to the captain
                  and vice-captain of your fantasy squad, so consider who you choose for these roles.</p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="single__tip">
                <p>Utilize current methods and tools. You may find player data, expert opinions, and
                  analysis of past performance, etc., that can help you choose a winning fantasy cricket
                  squad.
                </p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="single__tip">
                <p>Utilize player points from previous matches. To predict how well a player will do in
                  upcoming matchups, you can look at how many points they scored in previous games.</p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="single__tip">
                <p>Consider the overall balance of your team. Having a well-rounded squad with various
                  skill sets and experience levels is crucial. It helps to have a diverse group of people
                  working together to achieve your goals.</p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="single__tip">
                <p>Join the tournament as soon as that starts! Because, you know, the spots get filled
                  quickly! So, Play, Win and Thrive with Dzire11!</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <CTA />
      <FAQcricket />
    </>

  );
}

export default Cricket;