import React, { useEffect } from "react";
import { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
// import logo from "../assets/logo.png";
import logo from '../assets/updatedlogo/dzlogo2.png'
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import "yup-phone";

import "./styles.css";
import "./responsive.css";
import "./updated.css";

import step_chart from "../assets/step__chart.png";
import flag__india from "../assets/flag__india.png";
import flag__bang from "../assets/flag__bang.png";
import player__card from "../assets/player__card.png";
import contest from "../assets/contest.png";
import step__logo from "../assets/step__logo.png";
import user__img from "../assets/user__img.jpeg";
import user__img2 from "../assets/user__img2.jpeg";
import user__img3 from "../assets/user__img3.jpg";
import ios_button from "../assets/ios__button.png";
import android__button from "../assets/android__button.webp";
import { CTA } from "../components";
import { Link, useSearchParams } from "react-router-dom";
import greendownload from "../assets/red-android.svg";
import { Navbar } from "react-bootstrap";
import env from "../config/env"
import { sendInviteCode } from "../api/form.service";
const app_debug_url = env.app_debug_url
const playstoreurl = env.playstoreurl

function Home() {
  const [searchParams,setSearchParams] = useSearchParams();
  const invite_code = searchParams.get("invite_code")
  useEffect(()=>{
    if(invite_code){    
      sendInviteCode(invite_code)
    }
  })
  // const [number, setNumber] = useState("");
  // const body = "Your message here";

  // const onSubmit = async (e) => {
  //   await e.preventDefault();

  //   const res = await fetch("/api/sendMessage", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({ to: number, body: body }),
  //   });

  //   const data = await res.json();
  //   if (data.success) {
  //     await setNumber("");
  //   } else {
  //     await setNumber("An Error has occurred.");
  //   }
  // };

  return (
    <>
      <section>
        <section className="hero-bg">
          <Container className="hero__section">
            <Row className="h-100">
              <Col
                xs={12}
                md={7}
                className="d-flex flex-column justify-content-center align-items-center align-items-md-start"
              >
                <h1 className="hero-text">
                  play fantasy <span className="red-text">sports</span> and win
                  1cr daily<span className="red-text">!!</span>
                </h1>

                <p className="hero__decs">
                  Do you consider yourself a die-hard sports fan who wants to
                  deepen their commitment to their favorite teams? Willing to
                  gamify your skills and win cash? So, it’s time! Play. Win.
                  Cash. Fantasy sports with Dzire11 - Cash Out!
                </p>

                {/* SMS FORM */}

                {/* <h3 className="heading-text">Get <span className="red-text">Android</span> and <span className="red-text">iOS</span> app link through <span className="red-text">SMS</span></h3> */}

                {/* <Form className="cta__form" onSubmit={onSubmit}>
              <Row>
                <Col xs={12} md={6} lg={6}>

                <Form.Group controlId="phone">
                  <Form.Control
                    required
                    name="phone"
                    type="phone"
                    placeholder="Enter your mobile"
                    onChange={(e) => setNumber(e.target.value)}
                    value={number}
                />
                </Form.Group>
                </Col>
                <Col xs={12} md={6} lg={5}>
                  <Button
                    variant="danger"
                    type="submit"
                    className="main__button"
                    > get your link </Button>
                </Col >
              </Row>
            </Form> */}
              </Col>
              <Col
                xs={12}
                md={5}
                className="d-flex flex-column justify-content-center align-items-center"
              >
                <div className="download__btns text-center">
                  <Navbar.Brand href="/">
                    <img
                      className="bownload__btn"
                      src={ios_button}
                      alt="iOS App Download"
                    />
                  </Navbar.Brand>
                  <br />
                  <Navbar.Brand href={playstoreurl} target="_blank">
                    <img
                      className="bownload__btn"
                      src={android__button}
                      alt="Android App Download"
                    />
                  </Navbar.Brand>
                </div>
                <h4 className="text-center get__app red-text">Get The App</h4>
                <p className="text-center donwnload__paragraph">
                  Willing to have real fun? Well, Cash Your Desires with
                  Dzire11. Download the App and play!
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section>
          <Container className="stat_sections">
            <Row>
              <Col xs={12} md={6} className="stat_left">
                <div className="text-center">
                  <h3>10000+ users</h3>
                </div>
              </Col>

              <Col xs={12} md={6} className="stat_right">
                <div className="text-center">
                  <h3>1000+ daily contest</h3>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section>
          <Container className="step__section">
            <Row>
              <Col className="text-center">
                <h2>
                  ENJOY PLAYING <span className="red-text">FANTASY</span> SPORTS
                  AND WIN <span className="red-text">PRIZES!!!</span>
                </h2>
                <p>
                  Have you the desire to earn cash by gamifying your passion for
                  fantasy sports? Well, Dzire11 is the optimal environment for
                  your needs! Dzire11 welcomes you to the exciting world of
                  fantasy sports, where you'll get to create and manage your
                  virtual team of actual professional athletes. You can compete
                  with other teams, win a contest and earn cash!
                </p>
              </Col>
            </Row>

            <Row className="step__art__desktop">
              <Col lg={7}>
                <div className="d-flex flex-column justify-content-ceter hight-100">
                  <img
                    className="img-fluid step__main__graphic"
                    src={step_chart}
                    alt=""
                  />
                </div>
              </Col>
              <Col lg={5}>
                <div className="d-flex flex-column align-items-center hight-100 justify-content-between">
                  <div className="text-center">
                    <div className="STEP__1">
                      <h5 className="step__title">Select A Match</h5>
                      <p className="step__desc">
                        To begin, pick a sports league to attend. Choose the
                        sports league that most appeals to you, from football,
                        cricket, and Kabaddi.
                      </p>
                      <div className="match__team d-flex justify-content-center align-items-center">
                        <img
                          className="country_flag"
                          src={flag__india}
                          alt="Country flag"
                        />
                        <div className="vs__text">VS</div>
                        <img
                          className="country_flag"
                          src={flag__bang}
                          alt="Country flag"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="text-center">
                    <div className="STEP__2">
                      <h5 className="step__title">Draft your team</h5>
                      <p className="step__desc">
                        Make smart decisions based on your in-depth
                        understanding of the game and its players to assemble a
                        championship team.
                      </p>
                      <div className="player__cards">
                        <img
                          className="player__card"
                          src={player__card}
                          alt="team_player"
                        />
                        <img
                          className="player__card"
                          src={player__card}
                          alt="team_player"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="text-center">
                    <div className="STEP__3">
                      <h5 className="step__title">Join a Contest</h5>
                      <p className="step__desc">
                        Time to play! You should join a contest and begin
                        playing with your newly drafted squad.
                      </p>
                      <div className="contest__cards">
                        <img
                          className="contest__card"
                          src={contest}
                          alt="Contest"
                          width={300}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="step__art__mobile text-center">
              <Col>
                <img src={logo} alt="step - ogo" style={{width:'40%'}} />

                <div className="step__card__mobile">
                  <div className="step__number__wrap">
                    <div className="step__number">01</div>
                  </div>
                  <h5 className="step__title">Select A Match</h5>
                  <p className="step__desc">
                    To begin, pick a sports league to attend. Choose the sports
                    league that most appeals to you, from football, cricket, and
                    Kabaddi.
                  </p>
                  <div className="match__team d-flex justify-content-center align-items-center">
                    <img
                      className="country_flag"
                      src={flag__india}
                      alt="Country flag"
                    />
                    <div className="vs__text">VS</div>
                    <img
                      className="country_flag"
                      src={flag__bang}
                      alt="Country flag"
                    />
                  </div>
                </div>

                <div className="step__card__mobile mt-5">
                  <div className="step__number__wrap">
                    <div className="step__number">02</div>
                  </div>
                  <h5 className="step__title">Draft your team</h5>
                  <p className="step__desc">
                    Make smart decisions based on your in-depth understanding of
                    the game and its players to assemble a championship team.
                  </p>
                  <div className="player__cards">
                    <img
                      className="player__card"
                      src={player__card}
                      alt="team_player"
                    />
                    <img
                      className="player__card"
                      src={player__card}
                      alt="team_player"
                    />
                  </div>
                </div>

                <div className="step__card__mobile mt-5">
                  <div className="step__number__wrap">
                    <div className="step__number">03</div>
                  </div>
                  <h5 className="step__title">Join a Contest</h5>
                  <p className="step__desc">
                    Time to play! You should join a contest and begin playing
                    with your newly drafted squad.
                  </p>
                  <div className="contest__cards">
                    <img
                      className="contest__card_img"
                      src={contest}
                      alt="Contest main"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <CTA />

        {/* <section className="review__section">
          <Container>
            <Row>
              <Col>
                <div className="section__title__area">
                  <h2>REVIEWS AND RATINGS</h2>
                </div>

                <div className="reviews__box">
                  <div className="review__card card__1">
                    <Card className="review__single__card">
                      <Card.Body>
                        <div className="card__body d-flex">
                          <div className="review__img">
                            <img src={user__img} alt="" />
                          </div>
                          <div className="review__details">
                            <Card.Title>Mohan Verma</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">
                              Client
                            </Card.Subtitle>
                            <Card.Text>
                              Dzire11 has been my primary browser for the past
                              few months, and I am pretty impressed. The variety
                              of sports is impressive, and the UI is simple. It
                              was easy for me to claim the rewards I'd earned.
                              Strongly suggest a 5 stars rating from my end!
                            </Card.Text>
                            <p className="card__footer">2021.03.02</p>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>

                  <div className="review__card card__2">
                    <Card className="review__single__card">
                      <Card.Body>
                        <div className="card__body d-flex">
                          <div className="review__img">
                            <img src={user__img} alt="" />
                          </div>
                          <div className="review__details">
                            <Card.Title>Ankit chaudhary</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">
                              Developer
                            </Card.Subtitle>
                            <Card.Text>
                              My time with Dzire11 has been positive thus far.
                              It's easy to participate, and the prizes are fair.
                              I was a little confused about one contest, and the
                              support team helped guide me. So. Dzire 11
                              receives 4.5 out of 5 stars from me.
                            </Card.Text>
                            <p className="card__footer">2021.03.02</p>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>

                  <div className="review__card card__3">
                    <Card className="review__single__card">
                      <Card.Body>
                        <div className="card__body d-flex">
                          <div className="review__img">
                            <img src={user__img2} alt="" />
                          </div>
                          <div className="review__details">
                            <Card.Title>Kamal Kishor</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">
                              Tech Lead
                            </Card.Subtitle>
                            <Card.Text>
                              Are you wondering about the genuineness of
                              selecting winners? Well, worry not! Dzire11
                              selects the winner without any partiality, and
                              that’s based on the Algorithm! If your team scores
                              well, you will be the winner! And it provides
                              awards to the winning team. So, a solid 5-star
                              from my end!
                            </Card.Text>
                            <p className="card__footer">2021.03.02</p>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>

                  <div className="review__card card__4">
                    <Card className="review__single__card">
                      <Card.Body>
                        <div className="card__body d-flex">
                          <div className="review__img">
                            <img src={user__img3} alt="" />
                          </div>
                          <div className="review__details">
                            <Card.Title>Shalini Sharma</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">
                              CEO
                            </Card.Subtitle>
                            <Card.Text>
                              The best part about Dzire11 is you will never get
                              bored! You have a solid community to share
                              thoughts, and enjoy your time! As a fantasy sports
                              enthusiast, it’s the most crucial thing for me!
                              So, if you are also a fantasy sports lover, you
                              should opt for it without thinking twice!
                            </Card.Text>
                            <p className="card__footer">2021.03.02</p>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>

                  <div className="review__card card__5">
                    <Card className="review__single__card">
                      <Card.Body>
                        <div className="card__body d-flex">
                          <div className="review__img">
                            <img src={user__img2} alt="" />
                          </div>
                          <div className="review__details">
                            <Card.Title>Anil Kumar Sharma</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">
                              Player
                            </Card.Subtitle>
                            <Card.Text>
                              I've tried various fantasy sports sites, and
                              Dzire11 is far and by the best. The competitions
                              are organized quite professionally, and there is a
                              wide selection of sports to choose from.
                              Furthermore, the support staff is quite helpful
                              and quickly responds to inquiries. So, you must
                              give it a try!
                            </Card.Text>
                            <p className="card__footer">2021.03.02</p>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section> */}

        <section className="faq__section pb-5">
          <Container>
            <Row>
              <Col>
                <div className="section__title__area">
                  <h2>FREQUENTLY ASKED QUESTIONS</h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} className="p-md-2 p-lg-5">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Is Dzire11 a safe site to play?
                    </Accordion.Header>
                    <Accordion.Body>
                      Dzire11 has safeguards to ensure its website and app are
                      secure for players. Of course, it's wise to be cautious
                      with any online service, including this one, to prevent
                      unauthorized access to your personal and financial
                      details. Protect your account by using complex passwords,
                      staying wary of questionable emails and websites, and
                      never giving out your password to anybody.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      How can I get the Dzire11 app?
                    </Accordion.Header>
                    <Accordion.Body>
                      Search for "Dzire11" in the Google Play Store or "Dzire11"
                      in the Apple Store on your Android and iOS mobile device
                      to get the Dzire11 app. Find the Dzire11 logo app and hit
                      the "Install" button to add it to your smartphone. You can
                      also download the app directly from the Dzire11 website if
                      you follow the on-screen instructions.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      How can I use the Dzire11 app to play?
                    </Accordion.Header>
                    <Accordion.Body>
                      You may play the Dzire11 mobile game by downloading it
                      from the App Store or Google Play. Next, sign into
                      Dzire11, or sign up for a new account if you haven't
                      already. You can manage your teams, draught new players,
                      and make deals without leaving the app.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      What kinds of contests are available on Dzire11?
                    </Accordion.Header>
                    <Accordion.Body>
                      In addition to daily fantasy contests and season-long
                      leagues, Dzire11 hosts various additional contest formats
                      for a wide range of sports. On the Dzire11 website or app,
                      select the "Contests" page to see a list of current
                      competitions.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
              <Col xs={12} md={6} className="p-md-2 p-lg-5">
                <Accordion>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      {" "}
                      Can I make a contest on Dzire11?
                    </Accordion.Header>
                    <Accordion.Body>
                      Users can host competitions in Dzire11 and even ask others
                      to participate. Sign in to launch a contest and click the
                      "Create a Contest" link.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      How are points scored in Dzire11 fantasy leagues?
                    </Accordion.Header>
                    <Accordion.Body>
                      Your fantasy team's point total in Dzire11 leagues is
                      determined by the actual statistics of the players you've
                      selected. The point values assigned to various actions and
                      statistics will be established by the scoring system your
                      league commissioner has chosen or by the default scoring
                      system for the sport. After each week or game, your team's
                      score will be adjusted based on how well each player
                      performed.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Can I trust Dzire11 with my money?
                    </Accordion.Header>
                    <Accordion.Body>
                      Provided you take the usual care to safeguard your
                      personal and financial information, adding funds to your
                      Dzire11 account is safe. While Dzire11 takes precautions
                      to ensure the security of your personal information during
                      the payment processing phase, you should still take the
                      necessary precautions to safeguard your account.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      When will I receive my Dzire11 winnings?
                    </Accordion.Header>
                    <Accordion.Body>
                      After a Dzire11 competition or league has concluded and
                      the results have been verified, any monetary prizes won
                      will be added to the winner's account. Due to the time and
                      effort involved in calculating the statistics and points
                      for each player, this procedure can take some time to
                      conclude. Contact Dzire11's customer care if you need help
                      cashing in your prizes.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </Container>
        </section>
        {/* <div class="sticky_btns ">
        <div class="btnsbox">
          <Link to="/" target="_blank" class="p-0 download for android">
            <img
              src={greendownload}
              class="download for android"
              alt="download"
            />
          </Link>
          <a href="https://dqotsolutions.com/" target="_blank">
            Develop by DQOT
          </a>
        </div>
      </div> */}
      </section>
    </>
  );
}

export default Home;
