import axios from "axios"
import env from "../config/env";

axios.defaults.baseURL = env.baseUrl;

export const sendFormDetails = async (entry) => {
    try {

        const response = await axios.post('pages/send-contact-details', entry)
        if (response?.data?.status) {
            return true;
        } else {
            return false;
        }
    } catch (e) {
        console.error('e: ', e);

    }
}

export const withdraw_Amount = async (cb, entry, token) => {
    try {
        const response = await axios.post('/wallet/withdraw-influencer', entry, {
            headers: {
                "authorization": `Bearer ${token}`
            }
        })
        if (response?.data?.status) {
            return cb(response?.data);
        } else {
            return cb(response?.data);
        }
    } catch (e) {
        console.error('e: ', e);

    }
}

export const sendInviteCode = async (invite_code) => {
    try {
        const response = await axios.get(`pages/invite/add/${invite_code}`)
        if (response?.data?.status) {
            return true;
        } else {
            return false;
        }
    } catch (e) {
        console.error('e: ', e);

    }
}
export const getProfile = async (cb, token) => {
    try {
        const response = await axios.get('/auth/me', {
            headers: {
                "authorization": `Bearer ${token}`
            }
        })
        if (response?.data?.status) {
            return cb(response?.data);
        } else {
            return false;
        }
    } catch (e) {
        console.error('e: ', e);

    }
}


export const getAirPayPayload = async (cb, { token, amount, coupon }) => {
    try {
        // axios.defaults.baseURL = "http://13.233.51.81";
        const response = await axios.post('/wallet/add-airpay', { amount, coupon }, {
            headers: {
                "authorization": `Bearer ${token}`
            }
        })
        console.log('response?.data: ', response?.data);
        if (response?.data?.status) {
            return cb(response?.data);
        } else {
            return false;
        }
    } catch (e) {
        console.error('e: ', e);

    }
}




export const sendToAirPay = async (cb, { token, formData }) => {
    try {
        axios.defaults.baseURL = "https://payments.airpay.co.in";
        const response = await axios.post('/pay/index.php', formData,
            {
                'content-type': 'multipart/form-data'
            })
        console.log('sendToAirPay response?.data: ', response?.data);
        if (response?.data) {
            return cb(response?.data);
        } else {
            return false;
        }
    } catch (e) {
        console.error('e: ', e);

    }
}




