import React from "react";
import { Container, Row } from "react-bootstrap";

function RefundPolicy() {
  return (
    <>
      <section className="return_sec aboutpage">
        <Container>
          <Row>
            <div className="col-md-12">
              <div className="header-about">
                <h1 className="mainheading">Refund Policy</h1>
              </div>
            </div>
            <Row className=" justify-content-center">
              <div className="col-md-10">
                <div className="content">
                  <div>
                    <p className="ql-align-justify">
                      Any amount once added to the dzire11 account of user
                      can't be refunded back to the user in any case, and the
                      amount can be used to join the leagues, fun features and
                      Contest(s) organised by dzire11.
                    </p>
                    <p className="ql-align-justify">
                      The individuals using the services and Fun Features
                      provided by dzire11 are not permitted to cancel any
                      transaction which has been once confirmed. However, My
                      dzire11 is authorised to permit any user to cancel a
                      transaction and refund the transaction amount paid by the
                      user at its sole and absolute discretion, in an event :If
                      the User sends a written request to dzire11 from the
                      registered email Id to cancel such payment; or If the
                      payment is made for participation in the paid version(s)
                      of the Contest(s), the cancellation request must be
                      received at least 2 days prior to the commencement of the
                      round in respect of which the payment is made; dzire11
                      shall not be liable to refund any amount thereafter.
                    </p>
                    <p className="ql-align-justify">
                      The individuals using the services and Fun Features
                      provided by dzire11 are not permitted to cancel any
                      transaction which has been once confirmed. However, My
                      dzire11 is authorised to permit any user to cancel a
                      transaction and refund the transaction amount paid by the
                      user at its sole and absolute discretion, in an event :If
                      the User sends a written request to dzire11 from the
                      registered email Id to cancel such payment; or If the
                      payment is made for participation in the paid version(s)
                      of the Contest(s), the cancellation request must be
                      received at least 2 days prior to the commencement of the
                      round in respect of which the payment is made; dzire11
                      shall not be liable to refund any amount thereafter.
                    </p>
                    <p className="ql-align-justify">
                      The individuals using the services and Fun Features
                      provided by dzire11 are not permitted to cancel any
                      transaction which has been once confirmed. However, My
                      dzire11 is authorised to permit any user to cancel a
                      transaction and refund the transaction amount paid by the
                      user at its sole and absolute discretion, in an event :If
                      the User sends a written request to dzire11 from the
                      registered email Id to cancel such payment; or If the
                      payment is made for participation in the paid version(s)
                      of the Contest(s), the cancellation request must be
                      received at least 2 days prior to the commencement of the
                      round in respect of which the payment is made; dzire11
                      shall not be liable to refund any amount thereafter.
                    </p>
                  </div>
                </div>
              </div>
            </Row>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default RefundPolicy;
