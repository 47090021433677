import React from 'react';
import { Container, Row, Col, Accordion } from "react-bootstrap";

const faq__kabaddi = () => {
  return (
    <section className="faq__section py-5 faq__section_sub">
      <Container>
        <Row>
          <Col>
            <div className="section__title__area pt-5">
              <h2>FREQUENTLY ASKED QUESTIONS</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} className="p-md-2 p-lg-5">
            <Accordion >
              <Accordion.Item eventKey="0">
                <Accordion.Header>What is fantasy kabaddi?</Accordion.Header>
                <Accordion.Body>
                  The fantasy kabaddi game aims to simulate the experience of forming and managing a kabaddi
                  squad in a competitive environment. It’s a virtual space where you can play and enjoy time!
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>How do I create a team on your fantasy kabaddi platform?</Accordion.Header>
                <Accordion.Body>
                  Sign up for an account, then follow the on-screen steps to build a squad of up to seven players
                  using the allotted funds. And try to choose the best team possible to win the game!
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header> Can I join multiple fantasy kabaddi leagues?</Accordion.Header>
                <Accordion.Body>
                  It is possible to start your league or join an existing one. Within the bounds of your budget and
                  the game's regulations, you may also add or remove players from your team.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>How do I score points in fantasy kabaddi?</Accordion.Header>
                <Accordion.Body>
                  The statistics output of your squad's players determines how many points your squad is
                  awarded. You can have a look at the dashboard!!
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header>Is fantasy kabaddi in Dzire11 safe?</Accordion.Header>
                <Accordion.Body>
                  Dzire11 is entirely safe and legal. Fantasy Kabaddi is a fantasy sport which, instead of luck,
                  relies on skill. So you can play on our platform!
                </Accordion.Body>
              </Accordion.Item>

            </Accordion>
          </Col>
          <Col xs={12} md={6} className="p-md-2 p-lg-5">
            <Accordion >
              <Accordion.Item eventKey="5">
                <Accordion.Header>How do I know how my team is performing?</Accordion.Header>
                <Accordion.Body>
                  Your team's standing on the leaderboard is updated in real-time, and you get in-depth
                  information for each player. Check how your team is performing and what about others.

                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="6">
                <Accordion.Header>How long is a fantasy kabaddi season?</Accordion.Header>
                <Accordion.Body>
                  The season length in fantasy kabaddi might change from league to league. So you can choose
                  the right one at your convenience.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="7">
                <Accordion.Header>Can I play fantasy kabaddi for free?</Accordion.Header>
                <Accordion.Body>
                  Yes, we offer free and paid contests. You can start with free ones, and we are sure you will love
                  them! Then you can opt for the most advanced and interesting ones.

                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="8">
                <Accordion.Header>Can I play fantasy kabaddi with friends?</Accordion.Header>
                <Accordion.Body>
                  You can join a public league with other players or make your private league and invite your
                  friends to play. So, you get a fantastic community here.
                </Accordion.Body>
              </Accordion.Item>



              <Accordion.Item eventKey="9">
                <Accordion.Header>Can I earn money through Dzire11?</Accordion.Header>
                <Accordion.Body>
                  Yes, you can play and earn money if your team gets the most points and is declared the winner
                  of the contest that you are playing. So, play and earn with Dzire11!
                </Accordion.Body>
              </Accordion.Item>

            </Accordion>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default faq__kabaddi