import React from 'react'

function ShippingDeliveryPolicy() {
  return (
    <>
        <section className="shipping aboutpage">
        <div className="container">
          <div class="row">
            <div class="col-md-12">
              <div class="header-privacy">
                <h1 class="text-center">
                  <strong>Shipping and Delivery Policy</strong>
                </h1>
                <p>
                The following shipping and delivery policy pertains to the financial transactions conducted on Dzire11:
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="content pb-4">
                <h3>
                  <strong>1.	Digital Transactions:  </strong>
                </h3>
                <hr />
                <p>
                Dzire11 is an online fantasy sports platform; thus, there is no physical shipping or delivery of goods. All transactions, including deposits and withdrawals, are conducted digitally through the website or mobile application.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="content pb-4">
                <h3>
                  <strong>2.	Payment Methods: </strong>
                </h3>
                <hr />
                <p>
                Users can participate in leagues, deposit funds, and withdraw winnings using the available payment methods supported by Dzire11. These payment methods may include online banking, credit/debit cards, or other electronic payment options. Users are responsible for ensuring the accuracy of their payment information.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="content pb-4">
                <h3>
                  <strong>3.	Transaction Processing: </strong>
                </h3>
                <hr />
                <p>
                Dzire11 endeavors to process deposits and withdrawals promptly. However, certain factors beyond our control, such as bank processing times or technical issues with payment gateways, may cause delays. Dzire11 shall not be held responsible for such delays, but we will make reasonable efforts to resolve any issues promptly.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="content pb-4">
                <h3>
                  <strong>4.	Transaction Fees:  </strong>
                </h3>
                <hr />
                <p>
                Dzire11 may charge transaction fees for certain financial transactions, such as deposits or withdrawals. These fees, if applicable, will be communicated to users before confirming the transaction.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="content pb-4">
                <h3>
                  <strong>5.	Withdrawal Requirements: </strong>
                </h3>
                <hr />
                <p>
                Users may need to fulfill certain requirements, such as completing a minimum number of leagues or reaching a specific account balance, before being eligible to withdraw. These requirements are in place to ensure fair gameplay and prevent platform abuse.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="content pb-4">
                <h3>
                  <strong>6.	Security Measures:  </strong>
                </h3>
                <hr />
                <p>
                Dzire11 endeavors to provide a secure and reliable platform for all financial transactions. We implement industry-standard security measures to safeguard user data and maintain the confidentiality of financial information.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="content pb-4">
                <h3>
                  <strong>7.	Currency and Conversion: </strong>
                </h3>
                <hr />
                <p>
                Dzire11 operates in Indian Rupees (INR). Users participating in leagues or transactions in other currencies may be subject to currency conversion fees or fluctuations based on the prevailing exchange rates.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="content pb-4">
                <h3>
                  <strong>8.	Customer Support:  </strong>
                </h3>
                <hr />
                <p>
                In case of any issues or discrepancies regarding deposits, withdrawals, or transactions, users can contact Dzire11's customer support team for 
assistance. We will strive to address and resolve any concerns promptly.

                </p>
              </div>
            </div>
          </div>
          <p>Please note that the above Disclaimer and Shipping and Delivery Policy are general guidelines and may be subject to change. Users are advised to review the most recent version of these policies on the Dzire11 website or contact customer support for any clarifications.
            </p>
          </div>
        </section>
    </>
  )
}

export default ShippingDeliveryPolicy