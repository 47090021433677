import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { ChangeState } from "../../helpers/common";
import { sendFormDetails } from "../../api/form.service";


const initalFormValues = {
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    category: "",
    date: "",
    message: "",
}

const ContactForm = () => {
    const [form, setForm] = useState(initalFormValues)


    const handleForm = ChangeState(setForm)
    return (
        <Col xs={12} md={6} className="order-1 order-md-2">
            <div className="form__contact">
                <Form className="contact__form" onSubmit={async e => {
                    e.preventDefault()

                    await sendFormDetails(form)

                }} >
                    <Row className="mb-5">
                        <Col>
                            <Form.Control
                                id="fname"
                                name="firstName"
                                size="lg"
                                type="text"
                                placeholder="First name"
                                onChange={handleForm}
                            />
                        </Col>
                        <Col>
                            <Form.Control
                                name="lastName"
                                id="lname"
                                size="lg"
                                type="text"
                                placeholder="Last name"
                                onChange={handleForm}

                            />
                        </Col>
                    </Row>
                    <Row className="mb-5">
                        <Col>
                            <Form.Control
                                id="email"
                                name="email"
                                size="lg"
                                placeholder="Email"
                                onChange={handleForm}
                                type="email"
                            />
                        </Col>
                        <Col>
                            <Form.Control
                                id="phone"
                                name="contactNumber"
                                onChange={handleForm}
                                size="lg"
                                type="number"
                                placeholder="Contact No"
                            />
                        </Col>
                    </Row>
                    <Row className="mb-5">
                        <Col>
                            <Form.Select
                                onChange={handleForm}
                                name="category"
                                id="category"
                                size="lg"
                                defaultValue="Category"
                            >
                                <option value="">Select Category</option>

                                <option>Cricket</option>
                                <option>kabaddi</option>
                                <option>Football</option>
                                <option>Payment</option>
                                <option>Mobile App</option>
                                <option>Other</option>
                            </Form.Select>
                        </Col>
                        <Col>
                            <Form.Control
                                name="date"
                                id="date"
                                size="lg"
                                onChange={handleForm}
                                type="date"
                                min={new Date().toISOString().split("T")[0]}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-5">
                        <Col>
                            <Form.Control
                                id="message"
                                name="message"
                                onChange={handleForm}
                                as="textarea"
                                placeholder="Write your message"
                                rows={6}
                            />
                            <div className="form__error_allert2"></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                id="send__btton"
                                className="main__button"
                                variant="danger"
                                type="submit"
                            >
                                Send Message
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Col>
    )
}

export default ContactForm