import React from "react";
import { Navigation, Footer } from "./components";
import ScrollToTop from "./ScrollToTop";

function Layout({ children }) {
  return (
    <>
      {/* Render the header */}
      <Navigation />
      <ScrollToTop>
        {/* Render the content */}
        {children}

        {/* Render the footer */}
      </ScrollToTop>
      <Footer />
    </>
  );
}

export default Layout;
