import React from 'react';
import { Container, Row, Col } from "react-bootstrap";

const Tearms = () => {
  return (
    <section className='mb-5 terms_sec'>
      <Container>
        <Row className="py-5">
          <Col className="text-center">
            <h1> <strong>Terms and Conditions</strong> </h1>
          </Col>
        </Row>

        <Row>
          <Col>
            <h3><strong>Dzire11</strong></h3>
            <hr />
            <p>Dzire11 is a fantasy sports website where users can compete in many fantasy sports leagues
              like Fantasy Football, Fantasy Cricket, and Fantasy kabaddi! Users can build their own fantasy
              teams from a pool of available players and compete in tournaments. The success of a user's
              team is based on how its members perform in genuine athletic events.
            </p>
            <p>Winning teams in each competition get prizes following the rules and guidelines of that
              competition. Dzire11 provides players with the option of participating in both weekly and daily
              fantasy sports games, with the latter allowing users to assemble new teams for each
              competition. Dzire11 can be played legally in most places because it is a game of skill. The
              user, however, must check local regulations.</p>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <h3><strong>Usage of Dzire11</strong></h3>
            <hr />
            <p>By accessing or using the Dzire11 website or any of the services or products offered, you <strong>agree
              to be bound</strong> by these terms and conditions (<strong>the "Terms"</strong>). If you do not agree to all of the
              terms and conditions, then you may not use the Dzire11 website or any of the services or
              products offered on the website.
            </p>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <h3><strong>Intellectual Property</strong></h3>
            <hr />
            <p>The Dzire11 website and all of the content, including but not limited to text, graphics, images,
              software, and audio, contained on the website are the property of Dzire11 or its licensors and
              are protected by copyright and trademark laws. <strong>You may not use any content on the Dzire11
                website for any commercial purpose without the express written consent of Dzire11.
              </strong></p>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <h3><strong>Third-Party Sites, Services, and Products</strong></h3>
            <hr />
            <p>The Dzire11 website may contain links to third-party sites, services, and products. These links
              are provided for <strong>your convenience only</strong>, and Dzire11 is not responsible for the content or
              practices of these third-party sites. You should review the terms of use and privacy policies of
              these third-party sites before using them.</p>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <h3><strong>User Conduct</strong></h3>
            <hr />
            <p>By using the Dzire11 website, you agree to use the website and any of the services or products
              offered responsibly and legally. You may not use the Dzire11 website for any <strong>illegal or
                fraudulent purposes</strong> or in any way that could damage, disable, overburden, or impair the
              website or interfere with any other party's use and enjoyment. You may not attempt to gain
              <strong>unauthorized access</strong> to any services, products, other accounts, computer systems, or
              networks connected to the Dzire11 website through hacking, password mining, or other means.
            </p>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <h3><strong>Conditions of Participation</strong></h3>
            <hr />
            <p>To participate in any contests or games offered on the Dzire11 website, you must meet the
              following conditions:</p>
            <ul>
              <li>You must be at least <strong>18 years of age</strong>.</li>
              <li>You must be a <strong>legal resident</strong> of the country in which you are accessing the Dzire11
              </li>
              <li>You must not be a resident of a jurisdiction where participation in fantasy sports contests
                is <strong>prohibited by law.</strong></li>
            </ul>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <h3><strong> Registration for a contest</strong></h3>
            <hr />
            <p>You must complete the registration process to participate in a contest on the Dzire11 website.
              This includes providing your name, email address, username, and password. You are
              responsible for maintaining the confidentiality of your account and password and for any
              activities that occur under your account. You agree to notify Dzire11 immediately of any
              unauthorized use of your account or password.
            </p>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <h3><strong>Contest(s)</strong></h3>
            <hr />
            <p>The Dzire11 website offers a variety of contests for users to participate in. These contests may
              vary in format, rules, and prizes. You are responsible for reviewing each contest's rules and
              regulations before participating.
            </p>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <h3><strong>Participation and Prizes</strong></h3>
            <hr />
            <p>To participate in a contest, you must select a team of players from a given pool of players and
              enter your team into the contest. Your team's performance will be determined based on the
              actual performance of the players on your team in real-life sporting events. Prizes will be
              awarded to the <strong>top-performing teams</strong> in each contest based on the rules and regulations of
              the specific contest.</p>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <h3><strong>Contest Formats</strong></h3>
            <hr />
            <p>Contests on the Dzire11 website may be offered in various formats, including head-to-head,
              leagues, and tournaments. The specific format of each contest will be outlined in the rules.</p>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <h3><strong>The legality of Game of Skill</strong></h3>
            <hr />
            <p>Fantasy sports contests are considered games of skill in most jurisdictions. However, the legality
              of participating in fantasy sports contests may vary from state to state and country to country.
              Your responsibility is to ensure that participation in fantasy sports contests is legal in your
              jurisdiction.
            </p>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <h3><strong>Eligibility</strong></h3>
            <hr />
            <p>To be eligible to participate in a contest or to win a prize on the Dzire11 website, you must meet
              the following requirements:</p>

            <ul>
              <li>You must be at least 18 years of age.</li>
              <li>You must be a legal resident of the country in which you are accessing the Dzire11</li>
              <li>You must not be a resident of a jurisdiction where participation in fantasy sports contests is prohibited by law.</li>
              <li>You must not be an <strong>employee of Dzire11 or any of its affiliates</strong>.</li>
              <li>You must not be an <strong>immediate family member</strong> of an employee of Dzire11 or any of its affiliates.</li>
            </ul>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <h3><strong>Payment Terms</strong></h3>
            <hr />
            <p>Some contests on the Dzire11 website may require an entry fee. Entry fees must be paid
              through the methods accepted on the Dzire11 website. Dzire11 is <strong>not responsible</strong> for any
              fees or charges that may be incurred due to participating in a contest, including but not limited to
              currency exchange fees or credit card fees.
            </p>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <h3><strong> Selection, Verification of Winners, Conditions relating to the
              Prizes Distribution
            </strong></h3>
            <hr />
            <p> Winners of contests on the Dzire11 website will be determined based on the rules and
              regulations of the specific contest. The top-performing teams in each contest will be declared
              the winners. Dzire11 reserves the right to verify the eligibility of winners and to disqualify any
              winner who does not meet the eligibility requirements or has <strong>violated any of the terms and
                conditions of the website</strong>.
            </p>
            <p>Prizes will be awarded to the winners of each contest based on the rules and regulations of the
              specific game. Prizes may include cash, merchandise, or other items as determined by Dzire11.
              Prizes are non-transferable and cannot be redeemed for cash. Dzire11 is not responsible for
              any taxes or fees that may be incurred due to receiving a prize.</p>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <h3><strong> Publicity </strong></h3>
            <hr />
            <p> By participating in a contest on the Dzire11 website, you agree to allow Dzire11 to use your
              name, image, and likeness for promotional purposes. This may include, but is not limited to, the
              use of your name and image on the Dzire11 website, social media, and other marketing
              materials.
            </p>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <h3><strong> General Conditions </strong></h3>
            <hr />
            <p> Dzire11 reserves the right to modify or terminate any contest or service offered on the website
              at any time and for any reason without notice. Dzire11 reserves the right to modify or update
              these terms and conditions at any time, and you must review them regularly. Continued use of
              the Dzire11 website after any changes to the terms and conditions will constitute your
              acceptance of the changes.
            </p>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <h3><strong> Dispute and Dispute Resolution </strong></h3>
            <hr />
            <p>  Any disputes arising out of or in connection with the use of the Dzire11 website or the
              participation in any contests on the website will be <strong>resolved through binding arbitration</strong>
              following the rules of the Indian Arbitration Association</p>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <h3><strong> Release and Limitations of Liability
            </strong></h3>
            <hr />
            <p> By using the Dzire11 website, you release Dzire11 and its affiliates from any and all liability for
              any loss or damage of any kind that may arise out of or in connection with your use of the
              website or participation in any contests on the website.
            </p>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <h3><strong> Disclaimers </strong></h3>
            <hr />
            <p> The Dzire11 website and all the services and products offered are provided on an "as is" and
              "as available" basis. Dzire11 makes no representations or warranties of any kind, express or
              implied, as to the website's operation or the information, content, materials, or products included
              on the website.
            </p>
            <p> Dzire11 does not <strong>warrant that the website, services, or products</strong> will be uninterrupted or
              error-free. Dzire11 will not be liable for any interruptions or errors. In no event will Dzire11 or its
              affiliates be liable for any damages arising from the use of the website or participation in any
              contests on the website, including but not limited to direct, indirect, incidental, punitive, and
              consequential damages.
            </p>
            <p>The above limitations and exclusions may not apply to you, depending on your jurisdiction. You
              may have rights under local law that cannot be waived or limited by these terms and conditions.</p>
            <p>But you must check your local rules as per your state on Fantasy Sports. If you are from Assam,
              Andhra or Telangana States, you have rules on this. So be careful on that!</p>
            <p>By using the Dzire11 website, you acknowledge and agree that you are using the website and
              participating in any contests on the website at your own risk.</p>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <h3><strong> Governing Law </strong></h3>
            <hr />
            <p> These terms and conditions and your use of the Dzire11 website will be governed by and
              construed under the laws of the <strong>Indian Government</strong> without giving effect to any principles of
              conflicts of law. </p>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <h3><strong> Severability & Waiver </strong></h3>
            <hr />
            <p> Suppose any provision of these terms and conditions is found to be invalid or unenforceable.
              That provision will be enforced to the maximum extent possible, and the remaining provisions
              will remain in full force and effect. The failure of Dzire11 to exercise or enforce any right or
              provision of these terms and conditions <strong>will not constitute a waiver of such right or
                provision</strong>.
            </p>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <h3><strong> Entire Agreement </strong></h3>
            <hr />
            <p> These terms and conditions constitute the entire agreement between you and Dzire11 and
              govern your use of the Dzire11, superseding any prior agreements between you and Dzire11! </p>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <h3><strong> Privacy Policy </strong></h3>
            <hr />
            <p> Protecting our users' personal information is our top priority here at Dzire11. If you use the
              Dzire11 website or any of the services or goods offered on the website, then this Privacy Policy
              will explain how we collect, use, and disclose your personal information.
            </p>
            <p>Your use of the Dzire11 website constitutes your acceptance of this Privacy Policy and your
              consent to the collection, use, and sharing of your personal information in the ways specified
              herein. Do not use the Dzire11 website if you do not accept our standards and procedures.
            </p>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <h4 className='text-secondary'><strong> Information We Collect </strong></h4>
            <hr />
            <p> When you use the Dzire11 website or sign up for one of the site's many contests or games, we will collect some personal information from you. The following are examples of what this data might contain:
            </p>
            <ul>
              <li>Details about yourself include your name, address, and contact information.</li>
              <li>To complete a transaction on the Dzire11 website, we need financial information such as
                your credit card number or bank account details.</li>
              <li>To complete a transaction on the Dzire11 website, we need financial information such as
                your credit card number or bank account details.</li>
              <li>Personal data you give us when you get in touch with us, as in an email or through the
                website's "Contact Us" form.</li>
            </ul>
            <p>
              If you connect your Dzire11 account to a social media platform, we can access <strong>certain information about you from that account</strong>.
            </p>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <h4 className='text-secondary'><strong> Use of Your Individual Data </strong></h4>
            <hr />
            <p> Specifically, we put the data we acquire about you to use in the ways described below -
            </p>
            <ul>
              <li>To give you access to the Dzire11 website and its features, such as the ability to enter
                contests and play games</li>
              <li>To carry out and finalize any financial transactions initiated by you on the Dzire11
                website, such as the collection of entry fees or the distribution of prize mone</li>
              <li>To inform you of account changes, contests you've entered, and other important
                information related to the Dzire11 website and services.</li>
              <li>To tailor the Dzire11 website to the individual user by displaying material and adverts
                tailored to the individual's preferences.
              </li>
            </ul>
            <p>
              As part of our efforts to enhance the Dzire11 website and the services we offer, we regularly
              conduct analyses of user data to learn more about the site's visitors and how the site may be
              optimized.

            </p>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <h4 className='text-secondary'><strong> Disclosure of Your Personal Information </strong></h4>
            <hr />
            <p> For the reasons below, we may share your personal information with other parties:
            </p>
            <ul>
              <li> By third-party service providers, such as credit card processors, who help us provide the
                items and services advertised on the Dzire11 website to you.
              </li>
              <li>Third parties, such as advertisers and marketing partners, so that they can provide you
                with more relevant and engaging adverts.
              </li>
              <li>To the appropriate governmental or law enforcement authorities if we determine that
                such disclosure is required by law or is otherwise necessary to protect the rights,
                property, or safety of Dzire11, our users, or the general public.
              </li>

            </ul>
            <p>
              Your information may also be shared if we are involved in a <strong>business transaction such as a
                merger, acquisition, or selling of assets</strong>.
            </p>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <h3><strong> Cookies and Other Tracking Technologies </strong></h3>
            <hr />
            <p> Information about your visit to the Dzire11 website is gathered through tracking technology such
              as cookies. When you visit a website, it may store a <strong>small text file on your device as a
                cookie</strong>. Cookies let us analyze traffic patterns on the Dzire11 site to improve your visit.
              Disabling cookies in your browser settings may prevent you from using some parts of the
              website.
            </p>

          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <h4 className='text-secondary'><strong> Safety of Information </strong></h4>
            <hr />
            <p> We store all collected data in a secure environment to prevent unauthorized access, use, or
              disclosure. The security of your personal information is important to us, but remember that no
              method of communication over the internet or <strong>electronic storage method is 100% safe</strong>.
            </p>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <h4 className='text-secondary'><strong> Safeguarding Children's Confidentiality </strong></h4>
            <hr />
            <p> We <strong>cannot allow anyone under 18</strong> on the Dzire11 website for legal reasons. Information from
              minors under the age of 18 is never intentionally collected. To protect children's privacy, if we
              learn that we have collected data from a user under the age of 18, we will immediately erase the
              information.
            </p>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <h4 className='text-secondary'><strong> Alterations to the Privacy Policy</strong></h4>
            <hr />
            <p>This Privacy Statement may be revised periodically to <strong>reflect changes in our practices</strong> or for
              other business, legal, or regulatory requirements. Please check back frequently to see if the
              Privacy Policy has been updated.
            </p>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <h3><strong> Contact Information </strong></h3>
            <hr />
            <p> If you have any questions or concerns about these terms and conditions or the Dzire11 website,
              please contact us at <a href="mailto:support@dzire11.com">support@dzire11.com</a> </p>

          </Col>
        </Row>

      </Container>
    </section>
  )
}

export default Tearms