import React from "react";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../assets/logo.png";

import { NavLink, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import env from "../config/env"
const app_debug_url = env.app_debug_url
// ci cd comment
function Navigation() {
  const { pathname } = useLocation()

  if (env.noLayoutRoutes.includes(pathname)) {
    return <></>
  }
  return (

    <>

      <Navbar expand="md" className="sticky-top navbar-blur">
        <Container>
          <Navbar.Brand href={"/"}>
            <img className="logo" src={Logo} alt="" />
          </Navbar.Brand>
          <Navbar.Brand href={app_debug_url}>
            <Button className="btn downloadbtn d-xl-none d-lg-none">
              Download APP
              <svg
                width="16"
                height="16"
                viewBox="0 0 112 108"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M96.1 96.7C98.7 96.7 100.8 94.6 100.8 92V62.9H112V92.7C112 101.1 105.2 107.9 96.8 107.9H15.5C7.10005 107.9 0.300049 101.1 0.300049 92.7V62.9H11.5V92C11.5 94.6 13.6 96.7 16.2 96.7H96.1ZM34.1 0.700012H78V40H94.8L56.1 77.8L17.4 40H34.2001V0.700012H34.1Z"
                  fill="white"
                ></path>
              </svg>
            </Button>

          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav" className="justify-content-end">
            <Nav className="text-end ms-auto mobile-layout d-flex align-items-center">
              <Navbar.Toggle
                aria-controls="navbar-nav"
                className="custom-close"
              />

              <NavLink className="nav-link" to="/cricket">
                Cricket
              </NavLink>
              <NavLink className="nav-link" to="/kabaddi">
                Kabaddi
              </NavLink>
              <NavLink className="nav-link" to="/football">
                Football
              </NavLink>
              <Navbar.Brand href={app_debug_url}>
                <Button className="download_btn d-xl-block d-lg-block d-md-none d-none">
                  DOWNLOAD APP
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 112 108"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M96.1 96.7C98.7 96.7 100.8 94.6 100.8 92V62.9H112V92.7C112 101.1 105.2 107.9 96.8 107.9H15.5C7.10005 107.9 0.300049 101.1 0.300049 92.7V62.9H11.5V92C11.5 94.6 13.6 96.7 16.2 96.7H96.1ZM34.1 0.700012H78V40H94.8L56.1 77.8L17.4 40H34.2001V0.700012H34.1Z"
                      fill="white"
                    />
                  </svg>
                </Button>
              </Navbar.Brand>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Navigation;
