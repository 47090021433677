import React, { useState } from "react";
import wallet from "../../src/assets/Money_44325 1.png";
import { withdraw_Amount, getProfile } from "../api/form.service";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { HiArrowSmLeft } from "react-icons/hi";
import {
  getPrePayment,
  getPreWithdraw,
  getWithdrawSetting,
  influencer,
  withdraw,
} from "./services";
import toast, { Toaster } from "react-hot-toast";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSpring, animated } from "react-spring";

function Withdraw() {
  const [activeButton, setActiveButton] = useState(null);
  const [withdrawAmount, setWithdrawAmount] = useState(null);
  const [data, setData] = useState(null);
  const [dataa, setDataa] = useState(null);
  const [res, setRes] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  const page = searchParams.get("page");
  const navigate = useNavigate();

  const [wBkup, setWBkup] = useState([]);

  const [imps, setImps] = useState([]);
  const [showBottomSheet, setShowBottomSheet] = useState(false);

  const toggleButtonClose = () => {
    setShowBottomSheet(false);
  };

  const toggleButtonOpen = () => {
    if (withdrawAmount >= 100) {
      setShowBottomSheet(true);
    } else {
      toast.error("Please Enter a Valid Amount");
    }
  };

  const bottomSheetProps = useSpring({
    transform: showBottomSheet ? "translateY(-90%)" : "translateY(100%)",
  });
  const handleButtonClick = (buttonId, amount) => {
    setActiveButton(buttonId);
    setWithdrawAmount(amount);
  };

  const handleInputChange = (event) => {
    setWithdrawAmount(event.target.value);
  };
  const handleInput = (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    event.target.value = numericValue;
  };

  useEffect(() => {
    getProfile((d) => {
      setDataa(d.data);
    }, token);

    influencer.dashboard(
      null,
      (d) => {
        console.log(
          "🚀 ~ file: dashboard.js ~ line 25 ~ influencer.dashboard ~ d",
          d
        );
        setData(d?.data);
      },
      token
    );

    // getPreWithdraw()
    // getPrePayment()
    getWithdrawSetting((d) => {
      setImps(d?.data?.imps);
    }, token);
  }, []);

  // useEffect(() => {

  // }, []);

  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-root": {
              fontWeight: "bold",
            },
          },
        },
      },
    },
  });

  const handleWithdraw = () => {
    withdraw(withdrawAmount, (d) => {
      toast.success(d?.message);
      toggleButtonClose();
      setWithdrawAmount("");
      window.location.reload();
    });
  };



  const colors =[
  'orange',
  'green',
  '#db0000'
]

const bgColors =[
  '#ffa5002b',
  '#00800021',
  '#ff000021'
]


  return (
    <>
      <main>
        <Toaster position="top-center" reverseOrder={false} />
        <div className="mobileview">
          <div class="mobile-header">
            <HiArrowSmLeft
              className="back"
              style={{ fontSize: "27px" }}
              onClick={() => {
                navigate(-1);
              }}
            />
            <h6 className="mx-auto">Withdraw Cash</h6>
            {/* <HiArrowSmLeft style={{ fontSize: "27px", color: "DF0100" }} /> */}
          </div>
          <div className="amout_with">
            <h6 className="text-black text-center">
              {page === "affiliate" ? "Affiliate Balance" : "Current Balance"}
            </h6>
            <div className="wirhdraw_add">
              <img src={wallet} alt="#" className="img-fluid" />
              <h4 className="text-black">
                ₹{page === "affiliate" ? data?.balance || 0 : dataa?.balance}
              </h4>
            </div>
          </div>
          <div className="withdraw_card">
            <div className="withdraw_input">
              <ThemeProvider theme={theme}>
                <TextField
                  id="filled-helperText"
                  label="Amount to Withdraw"
                  defaultValue=""
                  variant="filled"
                  fullWidth
                  focused
                  color="success"
                  style={{ zIndex: "0" }}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*", // Use pattern to support some browsers
                  }}
                  value={withdrawAmount}
                  onInput={handleInput}
                  onChange={handleInputChange}
                />
              </ThemeProvider>
            </div>
            {/* <div className="withdraw_contions">
              <h6 className="text-black">Term and condition</h6>
              <ul>
                <li>Minimum amount to withdraw is ₹100.</li>
                <li>₹10 service charge per tranction.</li>
                <li>The withdrawl process will complete with in 24 hours.</li>
              </ul>
            </div> */}
            <div class="withdraw_tab_btn mt-4">
              <button
                className={`custom-button ${
                  activeButton === "button1" ? "active" : ""
                }`}
                id="button1"
                onClick={() => handleButtonClick("button1", "500")}
              >
                500
              </button>
              <button
                className={`custom-button ${
                  activeButton === "button2" ? "active" : ""
                }`}
                id="button2"
                onClick={() => handleButtonClick("button2", "1000")}
              >
                1000
              </button>
              <button
                className={`custom-button ${
                  activeButton === "button3" ? "active" : ""
                }`}
                id="button3"
                onClick={() => handleButtonClick("button3", "2000")}
              >
                2000
              </button>
            </div>
          </div>
          <div className="withdraw_card">
            <h6 class="text-black text-center mb-3">Instant Withdraw</h6>
            <div className="with_affil_heading d-flex align-items-center justify-content-between">
              <h5 className="amount_fee_heading w-100 text-center tran_b">
                Transaction Fee
              </h5>
              <h5 className="amount_fee_heading w-100 text-center">Amount</h5>
            </div>
            {imps.length > 0 &&
              imps.map((imp) => {
                return (
                  <div
                    className="amount_fee d-flex align-items-center justify-content-between"
                    key={imp?.value}
                  >
                    <p className="w-100 text-center left_am_b">{imp?.amount}</p>
                    <p className="w-100 text-center">{imp?.value}</p>
                  </div>
                );
              })}
          </div>
          <div
            className="withdraw_button text-center"
            onClick={() => {
              toggleButtonOpen();
              getPreWithdraw(withdrawAmount, (d) => {
                setWBkup(d?.data?.details);
              });
            }}
          >
            <button className="btn btn-primary btn_withdraw">
              Withdraw ₹{withdrawAmount}
            </button>
          </div>
          {showBottomSheet && (
            <div
              className="overlay_withdraw"
              onClick={() => {
                toggleButtonClose();
              }}
            ></div>
          )}
          <animated.div
            className="with_breakup_sheet text-center position-relative"
            style={{
              ...bottomSheetProps,
              display: showBottomSheet ? "block" : "none",
            }}
          >
            <div className="close_icon" onClick={toggleButtonClose}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.37151 6.78251L13.1715 1.98251C13.3605 1.7678 13.4604 1.48913 13.4511 1.20327C13.4417 0.917418 13.3238 0.645876 13.1212 0.443956C12.9187 0.242037 12.6468 0.124929 12.3609 0.116486C12.075 0.108043 11.7966 0.208899 11.5825 0.398513L6.78251 5.19851L1.98251 0.398513C1.88212 0.279716 1.75817 0.183047 1.6185 0.11462C1.47882 0.0461939 1.32647 0.0075 1.17107 0.000986224C1.01568 -0.00552755 0.860618 0.02028 0.715708 0.0767776C0.570798 0.133275 0.43919 0.219232 0.329211 0.329211C0.219232 0.43919 0.133275 0.570798 0.0767776 0.715708C0.02028 0.860618 -0.00552755 1.01568 0.000986224 1.17107C0.0075 1.32647 0.0461939 1.47882 0.11462 1.6185C0.183047 1.75817 0.279716 1.88212 0.398513 1.98251L5.19851 6.78251L0.398513 11.5825C0.216612 11.7978 0.12221 12.0735 0.134012 12.3551C0.145815 12.6366 0.262961 12.9035 0.462237 13.1028C0.661514 13.3021 0.92838 13.4192 1.20995 13.431C1.49153 13.4428 1.76726 13.3484 1.98251 13.1665L6.78251 8.36651L11.5825 13.1665C11.6829 13.2853 11.8069 13.382 11.9465 13.4504C12.0862 13.5188 12.2386 13.5575 12.394 13.564C12.5494 13.5706 12.7044 13.5447 12.8493 13.4882C12.9942 13.4317 13.1258 13.3458 13.2358 13.2358C13.3458 13.1258 13.4317 12.9942 13.4882 12.8493C13.5447 12.7044 13.5706 12.5494 13.564 12.394C13.5575 12.2386 13.5188 12.0862 13.4504 11.9465C13.382 11.8069 13.2853 11.6829 13.1665 11.5825L8.37151 6.78251Z"
                  fill="black"
                />
              </svg>
            </div>
            <div className="heading">
              <h4>Withdraw Breakup</h4>
            </div>
            <div className="amount_total">
              <h3> {wBkup[2]?.value}</h3>
              <p>Withdraw (after service fee)</p>
            </div>
            <div className="dication_card">
              {wBkup.length > 0 &&
                wBkup.map((p,index) => {
                  console.log(p?.color);
                  return (
                    <div className="total_with d-flex justify-content-between" style={{backgroundColor:bgColors[index]}}>
                    <h5 style={{ color: colors[index] }}>
                        {p?.title}
                      </h5>
                      <h5 style={{  color: colors[index] }}>
                        {p?.value}
                      </h5>
                    </div>
                  );
                })}
            </div>
            <div className="sbumit_withdrawl">
              <button
                className="btn btn-primary with_btn"
                onClick={handleWithdraw}
              >
                Withdraw
              </button>
            </div>
          </animated.div>
        </div>
      </main>
    </>
  );
}

export default Withdraw;

