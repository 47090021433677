import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import { influencer } from "../services"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-hot-toast";
import { LuRefreshCw } from "react-icons/lu"
import bat from "../../assets/img/bat.png";
import ftb_ball from "../../assets/img/ftb-ball.png";
import kabaddi from "../../assets/img/kabaddi.svg";
import multi_sports from "../../assets/img/multi-sports.png";
import Hand from "../../assets/img/Hand.png";
import deposit from "../../assets/img/deposit.png";
import trophy from "../../assets/img/trophy.png"
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import { red } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { BsCurrencyRupee } from 'react-icons/bs';
import InputAdornment from '@mui/material/InputAdornment';
import { getProfile, withdraw_Amount } from '../../api/form.service';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(red[900]),
  backgroundColor: red.A700,
  '&:hover': {
    backgroundColor: red[900],
  },
}));

const AffiliateProgram = () => {

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [startDate, setStartDate] = useState(
    moment().toDate()
  );
  const [endDate, setEndDate] = useState(moment().toDate());
  const [amount, setAmount] = useState(null)
  const [dataa, setDataa] = useState(null)

  const [values, setValues] = useState({
    from: null,
    to: null,
  });
  const [selectedOption, setSelectedOption] = useState("0");
  const [applied, setApplied] = useState(false)

  const [game_id, setGame_id] = useState("0");
  const [_game_id, _setGame_id] = useState("0");

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const token = searchParams.get("token")

  useEffect(() => {
    setApplied(false)
    setValues({
      from: moment(startDate).format("YYYY-MM-DD"),
      to: moment(endDate).format("YYYY-MM-DD 23:59:59"),
    });
    console.log(values);
  }, [startDate, endDate]);

  useEffect(() => {
    setValues({
      from: null,
      to: null,
    })
  }, []);

  const [data, setData] = useState(null);

  useEffect(() => {
    influencer.dashboard(
      null,
      (d) => {
        console.log(
          "🚀 ~ file: dashboard.js ~ line 25 ~ influencer.dashboard ~ d",
          d
        );
        setData(d?.data);
      },
      token
    );
  }, []);

  const handle_reset = () => {
    if (!token) {
      return;
    }
    setApplied(false)
    setGame_id("0")
    _setGame_id("0")
    setValues({
      from: null,
      to: null,
    })
    influencer.dashboard(
      null,
      (d) => {
        console.log(
          "🚀 ~ file: dashboard.js ~ line 25 ~ influencer.dashboard ~ d",
          d
        );
        setData(d?.data);
      },
      token
    );
  }

  const handleSubmit = (e, p) => {
    let obj = {
      from: p.startDate.format("YYYY-MM-DD"),
      to: p.endDate.format("YYYY-MM-DD 23:59:59"),
    };

    influencer.dashboard(
      obj,
      (d) => {
        console.log(
          "🚀 ~ file: dashboard.js ~ line 25 ~ influencer.dashboard ~ d",
          d
        );
        setData(d?.data);
      },
      token
    );
  };

  const getDetails = () => {
    if (!token) {
      return;
    }
    navigate(
      `/influencer-matches?from=${values.from || ""}&to=${values.to || ""}&token=${token}&game_id=${game_id}`
    );
  };

  const handle_select = (e) => {
    let val = e.target.value;
    setApplied(false);
    setGame_id(val);
    _setGame_id(val);
    setSelectedOption(val);
  };

  const handle_apply = () => {


    let t = toast.loading("loading...", { duration: 3000 });
    let obj = {
      from: values.from,
      to: values.to,
      game_id: game_id,
    };

    influencer.dashboard(
      obj,
      (d) => {
        toast.dismiss(t);

        _setGame_id(game_id);
        setData(d?.data);
        setApplied(true)
      },
      token,
      () => {
        toast.dismiss(t);
        _setGame_id(game_id);
        setData(null);
        setApplied(true)
      }
    );
  };

  const handleInputChange = (event) => {
    const numericValue = event.target.value.replace(/[^0-9]/g, '');
    setAmount(Number(numericValue))
  }

  useEffect(()=>{
    getProfile((d)=>{
      setDataa(d.data)
    },token)
  },[])


  return (
    <main>
      <div className="mobileview">
        <div className="mobile-header">
          {/* <i className="fa-solid fa-arrow-left" onClick={() => { router.back }} /> */}
          <h6 className="mx-auto" >AFFILIATE PROGRAM</h6>
        </div>
        <div className="mobile-main">
          <div className="filter-section" >
            <div className="Choose-team-select">
              <select
                value={selectedOption}
                className="form-select"
                aria-label="Default select example"
                onChange={handle_select}
              >
                <option value={"0"}>
                  All Games
                </option>
                <option value={"1"}>
                  Cricket
                </option>
                <option value="5">Football</option>
                <option value="6">Kabaddi</option>
              </select>
            </div>
            <div className="start-end-btn">
              {/* <input type={"date"} /> */}
              {/* <DateRangePicker onApply={handleSubmit} initialSettings={{ startDate: moment().subtract(6, 'days').format("MM/DD/YYYY"), endDate: moment().format("MM/DD/YYYY") }} > */}
              {/* <label htmlFor="start_date" className="btn btn-primary" >START DATE</label> */}
              {/* <input type="date" id="start_date" style={{ display: "block" }} /> */}
              <div className="btn btn-primary start_date_btn">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  className="start_date_btn_input"
                />
                {values.from ?? "START DATE"}
              </div>
              {/* </DateRangePicker> */}

              <div className="end-btn btn start_date_btn">
                <DatePicker
                  selected={endDate}
                  onChange={(date) => {
                    setEndDate(date);
                  }}
                  className="start_date_btn_input"
                />
                {values.to ? moment(endDate).format("YYYY-MM-DD") : "END DATE"}
              </div>
              {/* <button className="end-btn btn">END DATE</button> */}
            </div>
            <div className="get-btn alskjglksd">
              <button className={`cmn-btn apply-filter-btn ${applied ? "applied-filter-btn" : ""}`} onClick={handle_apply}>
                {
                  applied ? "APPLIED" : "APPLY"
                }

              </button>
              <button className="cmn-btn reset-filter-btn" onClick={handle_reset} >
                <LuRefreshCw />
              </button>
            </div>

          </div>

          <div className="row">
            <div className="col-6">
              <div className="match-content">
                <div className="match-icon">
                  <img
                    src={
                      _game_id == "1"
                        ? bat
                        : _game_id == "5"
                          ? ftb_ball
                          : _game_id == "6"
                            ? kabaddi
                            : multi_sports
                    }
                    alt="#"
                    width={45}
                    height={44}
                  />
                  <h6>MATCH</h6>
                  <h5>{data?.matches || 0}</h5>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="match-content">
                <div className="match-icon">
                  <img src={Hand} alt="#" />
                  <h6>TEAM JOINED</h6>
                  <h5>{data?.team_joins || 0}</h5>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="match-content">
                <div className="match-icon">
                  <img src={deposit} alt="#" />
                  <h6>DEPOSIT</h6>
                  <h5>₹{(data?.deposit || 0).toFixed(2)}</h5>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="match-content">
                <div className="match-icon">
                  <img src={trophy} alt="#" />
                  <h6>WINNING</h6>
                  <h5>₹{(data?.winning || 0).toFixed(2)}</h5>
                </div>
              </div>
            </div>
            <div className="mobile-blance d-flex justify-content-between">
              <h5 >AFFILIATION BALANCE:</h5>
              <h5>₹{(data?.balance || 0).toFixed(2)}</h5>
            </div>
            <div className="col-12 mt-3 text-center">
              <div className="get-btn">
                <button className="btn get" onClick={getDetails}>
                  VIEW DETAILS
                </button>



                {/* {dataa?.id==="73277fda-7c40-4f42-9b1d-6ef87d39224c"||dataa?.id==="2379c666-b7df-417b-aa5c-1fd66a2c19b2" ?
                <Link to={`/withdraw?token=${token}&page=affiliate`} >
               <button className="btn withdraw" onClick={handleClickOpen}>
                  WITHDRAW
                </button>
                </Link>
                :
                <></>} */}
                <Link to={`/withdraw?token=${token}&page=affiliate`} >
               <button className="btn withdraw" onClick={handleClickOpen}>
                  WITHDRAW
                </button>
                </Link>

              </div>
            </div>
            {/* <div className="col-6 mt-3">
              <div className="get-btn">
              <Link to={'/withdraw'}>
                <button className="withdraw">
                  WITHDRAW
                </button>
              </Link>
              </div>
            </div> */}
          </div>
          {/* <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
          >
            <AppBar sx={{ position: 'relative', bgcolor: "#00610E" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  Withdraw Money
                </Typography>
              </Toolbar>
            </AppBar>
            <Container maxWidth="sm" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", margin: "30px  0" }}>
              <Typography variant="h5" sx={{ margin: "20px 0" }} gutterBottom>
                Enter Amount
              </Typography>
              <TextField id="filled-basic" label="Amount"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BsCurrencyRupee />
                    </InputAdornment>
                  ),
                  maxLength: 10,
                  inputMode: 'numeric'
                }}
                variant="filled"
                color='error'
                value={amount}
                onChange={handleInputChange} />
              <ColorButton variant="contained" sx={{ width: "200px", margin: "40px 0" }}
               onClick={async e => {
                     await withdraw_Amount( {amount:amount}).then(response => {
                      if (response?.data?.status) {
                        return handleClose;
                    } else {
                        return false;
                    }
                  })
                  .catch(error => {
                      console.error(error);
                  });

                }}> Withdraw</ColorButton>
            </Container>
          </Dialog> */}
        </div>
      </div>
    </main>
  );
};

export default AffiliateProgram;
