import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// These code lines for a firebase connections. After creating a new database on the firebase, you can find the this code.
// If you going to change your firebase account or database you need to repalce this code

const firebaseConfig = {
  apiKey: "AIzaSyAtETbL6Vq_VJeAxh891eIO44UbF-EyCkU",
  authDomain: "dzire11prod.firebaseapp.com",
  projectId: "dzire11prod",
  storageBucket: "dzire11prod.appspot.com",
  messagingSenderId: "919529132952",
  appId: "1:919529132952:web:1a7e7bcf62da4e2aa491ed"
};

// Initialize Firebase

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);