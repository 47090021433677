export const ChangeState = (state) => (e) => {
    state((p) => {
        // console.log("insidechangestaate", p)
        return {
            ...p,
            [e.target.name]: e.target.value
        };
    });

    return true;
};