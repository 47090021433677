import React from "react";
import { Container, Row, Tabs, Tab, Accordion } from "react-bootstrap";
import cricketicon from "../assets/cricket.png";
import footballicon from "../assets/football.png";
import kabaddiicon from "../assets/kabaddi.png";
import baseballicon from "../assets/baseball.png";
import basketballicon from "../assets/basketball.png";
import hockeyicon from "../assets/hockey.png";
import handballicon from "../assets/handball.png";
import cricketPoint from "../assets/cricket-sport-img.jpg";
import Football from "./Football";

function FantasyPointsSystem() {
  return (
    <>
      <section className="privacy aboutpage">
        <Container>
          <Row>
            <div className="col-md-12">
              <div className="header-privacy">
                <h1 className="text-center p-4">
                  <strong>FANTASY POINTS SYSTEM</strong>
                </h1>
              </div>
            </div>
          </Row>
          <Row>
            <div className="col-md-12">
              <div className="point_tab_main">
                <Tabs
                  defaultActiveKey="cricket"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab
                    eventKey="cricket"
                    title={
                      <span className="text-center">
                        <img
                          src={cricketicon}
                          width={60}
                          alt="icon"
                          className="img-fluid d-block tab_icon"
                        />
                        Cricket
                      </span>
                    }
                  >
                    <Row className="justify-content-center mt-5">
                      <div className="col-md-10">
                        <div className="fantasy_point_under_tab">
                          <Tabs
                            defaultActiveKey="T20"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                          >
                            <Tab eventKey="T20" title="T20">
                              <div className="points_accodion">
                                <Accordion defaultActiveKey="0">
                                  <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                      Batting Points
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="points-body">
                                        <div className="point-image">
                                          <img
                                            src={cricketPoint}
                                            alt="#"
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div className="table-responsive">
                                          <table className="table">
                                            <thead>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Every run scored</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>1</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Every boundary hit</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>1</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Every six-hit</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>2</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Half Century (50 runs scored
                                                    by a batsman in a single
                                                    innings)
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>8</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Century (100 runs scored by
                                                    a batsman in a single
                                                    innings)
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>16</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Dismissal for a Duck (only
                                                    for batsmen, wicket-keepers
                                                    and all-rounders)
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>-2</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>30 run Bonus</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>4</span>
                                                </td>
                                              </tr>
                                            </thead>
                                          </table>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                      Bowling Points
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="points-body">
                                        <div className="point-image">
                                          <img
                                            src={cricketPoint}
                                            alt="#"
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div className="table-responsive">
                                          <table className="table">
                                            <thead>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Every wicket taken
                                                    (excluding run out)
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>25</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>3 wickets</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>4</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>4 wickets</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>8</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>5 wickets</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>16</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Maiden over</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>12</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Bonus (LBW / Bowled)</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>8</span>
                                                </td>
                                              </tr>
                                            </thead>
                                          </table>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="2">
                                    <Accordion.Header>
                                      Fielding Points
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="points-body">
                                        <div className="point-image">
                                          <img
                                            src={cricketPoint}
                                            alt="#"
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div class="table-responsive">
                                          <table class="table">
                                            <thead>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Catch taken</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>8</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Caught &amp; Bowled</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>33</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Stumping/ Run Out (direct)
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>12</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Run Out (Thrower)</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>6</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Run Out (Catcher)</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>6</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>3 Catch Bonus</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>4</span>
                                                </td>
                                              </tr>
                                            </thead>
                                          </table>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="3">
                                    <Accordion.Header>
                                      Other Points
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="points-body">
                                        <div className="point-image">
                                          <img
                                            src={cricketPoint}
                                            alt="#"
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div class="table-responsive">
                                          <table class="table">
                                            <thead>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Captain</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>2</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Vice-Captain</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>1.5</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Being a part of the starting
                                                    XI
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>4</span>
                                                </td>
                                              </tr>
                                            </thead>
                                          </table>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="4">
                                    <Accordion.Header>
                                      Economy Rate Points
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="points-body">
                                        <div className="point-image">
                                          <img
                                            src={cricketPoint}
                                            alt="#"
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div class="table-responsive">
                                          <table class="table">
                                            <thead>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Minimum overs bowled by
                                                    player to be applicable
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>2</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Below 5 runs per over</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>6</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Between 5 and 5.99 runs per
                                                    over
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>4</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Between 6 and 7 runs per
                                                    over
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>2</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Between 10 and 11 runs per
                                                    over
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>-2</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Between 11.01 and 12 runs
                                                    per over
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>-4</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Above 12 runs per over
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>-6</span>
                                                </td>
                                              </tr>
                                            </thead>
                                          </table>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="5">
                                    <Accordion.Header>
                                      Strike Rate (Except Bowler) Points
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="points-body">
                                        <div className="point-image">
                                          <img
                                            src={cricketPoint}
                                            alt="#"
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div class="table-responsive">
                                          <table class="table">
                                            <thead>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Minimum balls faced by a
                                                    player to be applicable
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>10</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Between 60 and 70 runs per
                                                    100 balls
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>-2</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Between 50 and 59.99 runs
                                                    per 100 balls
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>-4</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Below 50 runs per 100 balls
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>-6</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Between 130 and 150 runs per
                                                    100 balls
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>2</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Between 150.01 and 170 runs
                                                    per 100 balls
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>4</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Above 170 runs per 100 balls
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>6</span>
                                                </td>
                                              </tr>
                                            </thead>
                                          </table>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="6">
                                    <Accordion.Header>
                                      Other Important Points
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="points-body">
                                        <div class="points-data-content">
                                          <ul>
                                            <li>
                                              <strong>
                                                {" "}
                                                Rules about substitutes:
                                              </strong>
                                            </li>
                                            <li>
                                              All the X-Factor substitutes will
                                              get 4 points for being announced
                                              and will also score points for any
                                              contributions they make as per our
                                              Fantasy Point System.
                                            </li>
                                            <li>
                                              Concussion &amp; COVID-19
                                              Substitutes, if permitted as per
                                              the applicable rules and
                                              regulations of the tournament,
                                              will be awarded 4 points for
                                              making an appearance (only for
                                              T20s, ODs, Tests, T10s and The
                                              Hundred) and will be awarded
                                              points for all the contributions
                                              they make as per the Fantasy Point
                                              System.
                                            </li>
                                            <li>
                                              Substitutes other than Concussion
                                              substitutes, COVID-19 substitutes
                                              or X-Factor substitutes will not
                                              be awarded points for any
                                              contribution they make.
                                            </li>
                                            <li>
                                              In the Big Bash League, if a
                                              player gets replaced by an
                                              X-Factor substitute, but later
                                              comes back on field, he will get
                                              points for his contributions.
                                              However, if a player, who was not
                                              a part of the announced lineups,
                                              comes to the field as a
                                              substitute, he will not get points
                                              for any of his contributions
                                              (except Concussion and COVID-19
                                              substitutes).
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </div>
                            </Tab>
                            <Tab eventKey="ODI" title="ODI">
                            <div className="points_accodion">
                                <Accordion defaultActiveKey="0">
                                  <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                      Batting Points
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="points-body">
                                        <div className="point-image">
                                          <img
                                            src={cricketPoint}
                                            alt="#"
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div className="table-responsive">
                                          <table className="table">
                                            <thead>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Every run scored</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>1</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Every boundary hit</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>1</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Every six-hit</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>2</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Half Century (50 runs scored
                                                    by a batsman in a single
                                                    innings)
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>8</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Century (100 runs scored by
                                                    a batsman in a single
                                                    innings)
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>16</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Dismissal for a Duck (only
                                                    for batsmen, wicket-keepers
                                                    and all-rounders)
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>-2</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>30 run Bonus</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>4</span>
                                                </td>
                                              </tr>
                                            </thead>
                                          </table>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                      Bowling Points
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="points-body">
                                        <div className="point-image">
                                          <img
                                            src={cricketPoint}
                                            alt="#"
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div className="table-responsive">
                                          <table className="table">
                                            <thead>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Every wicket taken
                                                    (excluding run out)
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>25</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>3 wickets</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>4</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>4 wickets</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>8</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>5 wickets</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>16</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Maiden over</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>12</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Bonus (LBW / Bowled)</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>8</span>
                                                </td>
                                              </tr>
                                            </thead>
                                          </table>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="2">
                                    <Accordion.Header>
                                      Fielding Points
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="points-body">
                                        <div className="point-image">
                                          <img
                                            src={cricketPoint}
                                            alt="#"
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div class="table-responsive">
                                          <table class="table">
                                            <thead>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Catch taken</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>8</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Caught &amp; Bowled</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>33</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Stumping/ Run Out (direct)
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>12</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Run Out (Thrower)</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>6</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Run Out (Catcher)</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>6</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>3 Catch Bonus</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>4</span>
                                                </td>
                                              </tr>
                                            </thead>
                                          </table>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="3">
                                    <Accordion.Header>
                                      Other Points
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="points-body">
                                        <div className="point-image">
                                          <img
                                            src={cricketPoint}
                                            alt="#"
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div class="table-responsive">
                                          <table class="table">
                                            <thead>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Captain</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>2</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Vice-Captain</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>1.5</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Being a part of the starting
                                                    XI
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>4</span>
                                                </td>
                                              </tr>
                                            </thead>
                                          </table>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="4">
                                    <Accordion.Header>
                                      Economy Rate Points
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="points-body">
                                        <div className="point-image">
                                          <img
                                            src={cricketPoint}
                                            alt="#"
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div class="table-responsive">
                                          <table class="table">
                                            <thead>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Minimum overs bowled by
                                                    player to be applicable
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>2</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Below 5 runs per over</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>6</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Between 5 and 5.99 runs per
                                                    over
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>4</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Between 6 and 7 runs per
                                                    over
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>2</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Between 10 and 11 runs per
                                                    over
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>-2</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Between 11.01 and 12 runs
                                                    per over
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>-4</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Above 12 runs per over
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>-6</span>
                                                </td>
                                              </tr>
                                            </thead>
                                          </table>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="5">
                                    <Accordion.Header>
                                      Strike Rate (Except Bowler) Points
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="points-body">
                                        <div className="point-image">
                                          <img
                                            src={cricketPoint}
                                            alt="#"
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div class="table-responsive">
                                          <table class="table">
                                            <thead>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Minimum balls faced by a
                                                    player to be applicable
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>10</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Between 60 and 70 runs per
                                                    100 balls
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>-2</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Between 50 and 59.99 runs
                                                    per 100 balls
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>-4</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Below 50 runs per 100 balls
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>-6</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Between 130 and 150 runs per
                                                    100 balls
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>2</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Between 150.01 and 170 runs
                                                    per 100 balls
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>4</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Above 170 runs per 100 balls
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>6</span>
                                                </td>
                                              </tr>
                                            </thead>
                                          </table>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="6">
                                    <Accordion.Header>
                                      Other Important Points
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="points-body">
                                        <div class="points-data-content">
                                          <ul>
                                            <li>
                                              <strong>
                                                {" "}
                                                Rules about substitutes:
                                              </strong>
                                            </li>
                                            <li>
                                              All the X-Factor substitutes will
                                              get 4 points for being announced
                                              and will also score points for any
                                              contributions they make as per our
                                              Fantasy Point System.
                                            </li>
                                            <li>
                                              Concussion &amp; COVID-19
                                              Substitutes, if permitted as per
                                              the applicable rules and
                                              regulations of the tournament,
                                              will be awarded 4 points for
                                              making an appearance (only for
                                              T20s, ODs, Tests, T10s and The
                                              Hundred) and will be awarded
                                              points for all the contributions
                                              they make as per the Fantasy Point
                                              System.
                                            </li>
                                            <li>
                                              Substitutes other than Concussion
                                              substitutes, COVID-19 substitutes
                                              or X-Factor substitutes will not
                                              be awarded points for any
                                              contribution they make.
                                            </li>
                                            <li>
                                              In the Big Bash League, if a
                                              player gets replaced by an
                                              X-Factor substitute, but later
                                              comes back on field, he will get
                                              points for his contributions.
                                              However, if a player, who was not
                                              a part of the announced lineups,
                                              comes to the field as a
                                              substitute, he will not get points
                                              for any of his contributions
                                              (except Concussion and COVID-19
                                              substitutes).
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </div>
                            </Tab>
                            <Tab eventKey="TEST" title="TEST">
                            <div className="points_accodion">
                                <Accordion defaultActiveKey="0">
                                  <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                      Batting Points
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="points-body">
                                        <div className="point-image">
                                          <img
                                            src={cricketPoint}
                                            alt="#"
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div className="table-responsive">
                                          <table className="table">
                                            <thead>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Every run scored</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>1</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Every boundary hit</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>1</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Every six-hit</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>2</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Half Century (50 runs scored
                                                    by a batsman in a single
                                                    innings)
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>8</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Century (100 runs scored by
                                                    a batsman in a single
                                                    innings)
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>16</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Dismissal for a Duck (only
                                                    for batsmen, wicket-keepers
                                                    and all-rounders)
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>-2</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>30 run Bonus</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>4</span>
                                                </td>
                                              </tr>
                                            </thead>
                                          </table>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                      Bowling Points
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="points-body">
                                        <div className="point-image">
                                          <img
                                            src={cricketPoint}
                                            alt="#"
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div className="table-responsive">
                                          <table className="table">
                                            <thead>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Every wicket taken
                                                    (excluding run out)
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>25</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>3 wickets</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>4</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>4 wickets</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>8</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>5 wickets</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>16</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Maiden over</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>12</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Bonus (LBW / Bowled)</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>8</span>
                                                </td>
                                              </tr>
                                            </thead>
                                          </table>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="2">
                                    <Accordion.Header>
                                      Fielding Points
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="points-body">
                                        <div className="point-image">
                                          <img
                                            src={cricketPoint}
                                            alt="#"
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div class="table-responsive">
                                          <table class="table">
                                            <thead>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Catch taken</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>8</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Caught &amp; Bowled</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>33</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Stumping/ Run Out (direct)
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>12</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Run Out (Thrower)</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>6</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Run Out (Catcher)</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>6</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>3 Catch Bonus</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>4</span>
                                                </td>
                                              </tr>
                                            </thead>
                                          </table>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="3">
                                    <Accordion.Header>
                                      Other Points
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="points-body">
                                        <div className="point-image">
                                          <img
                                            src={cricketPoint}
                                            alt="#"
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div class="table-responsive">
                                          <table class="table">
                                            <thead>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Captain</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>2</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Vice-Captain</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>1.5</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Being a part of the starting
                                                    XI
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>4</span>
                                                </td>
                                              </tr>
                                            </thead>
                                          </table>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="4">
                                    <Accordion.Header>
                                      Other Important Points
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="points-body">
                                        <div class="points-data-content">
                                          <ul>
                                            <li>
                                              <strong>
                                                {" "}
                                                Rules about substitutes:
                                              </strong>
                                            </li>
                                            <li>
                                              All the X-Factor substitutes will
                                              get 4 points for being announced
                                              and will also score points for any
                                              contributions they make as per our
                                              Fantasy Point System.
                                            </li>
                                            <li>
                                              Concussion &amp; COVID-19
                                              Substitutes, if permitted as per
                                              the applicable rules and
                                              regulations of the tournament,
                                              will be awarded 4 points for
                                              making an appearance (only for
                                              T20s, ODs, Tests, T10s and The
                                              Hundred) and will be awarded
                                              points for all the contributions
                                              they make as per the Fantasy Point
                                              System.
                                            </li>
                                            <li>
                                              Substitutes other than Concussion
                                              substitutes, COVID-19 substitutes
                                              or X-Factor substitutes will not
                                              be awarded points for any
                                              contribution they make.
                                            </li>
                                            <li>
                                              In the Big Bash League, if a
                                              player gets replaced by an
                                              X-Factor substitute, but later
                                              comes back on field, he will get
                                              points for his contributions.
                                              However, if a player, who was not
                                              a part of the announced lineups,
                                              comes to the field as a
                                              substitute, he will not get points
                                              for any of his contributions
                                              (except Concussion and COVID-19
                                              substitutes).
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </div>
                            </Tab>
                            <Tab eventKey="T10" title="T10">
                            <div className="points_accodion">
                                <Accordion defaultActiveKey="0">
                                  <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                      Batting Points
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="points-body">
                                        <div className="point-image">
                                          <img
                                            src={cricketPoint}
                                            alt="#"
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div className="table-responsive">
                                          <table className="table">
                                            <thead>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Every run scored</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>1</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Every boundary hit</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>1</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Every six-hit</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>2</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Half Century (50 runs scored
                                                    by a batsman in a single
                                                    innings)
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>8</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Century (100 runs scored by
                                                    a batsman in a single
                                                    innings)
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>16</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Dismissal for a Duck (only
                                                    for batsmen, wicket-keepers
                                                    and all-rounders)
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>-2</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>30 run Bonus</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>4</span>
                                                </td>
                                              </tr>
                                            </thead>
                                          </table>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                      Bowling Points
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="points-body">
                                        <div className="point-image">
                                          <img
                                            src={cricketPoint}
                                            alt="#"
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div className="table-responsive">
                                          <table className="table">
                                            <thead>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Every wicket taken
                                                    (excluding run out)
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>25</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>3 wickets</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>4</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>4 wickets</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>8</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>5 wickets</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>16</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Maiden over</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>12</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Bonus (LBW / Bowled)</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>8</span>
                                                </td>
                                              </tr>
                                            </thead>
                                          </table>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="2">
                                    <Accordion.Header>
                                      Fielding Points
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="points-body">
                                        <div className="point-image">
                                          <img
                                            src={cricketPoint}
                                            alt="#"
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div class="table-responsive">
                                          <table class="table">
                                            <thead>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Catch taken</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>8</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Caught &amp; Bowled</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>33</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Stumping/ Run Out (direct)
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>12</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Run Out (Thrower)</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>6</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Run Out (Catcher)</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>6</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>3 Catch Bonus</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>4</span>
                                                </td>
                                              </tr>
                                            </thead>
                                          </table>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="3">
                                    <Accordion.Header>
                                      Other Points
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="points-body">
                                        <div className="point-image">
                                          <img
                                            src={cricketPoint}
                                            alt="#"
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div class="table-responsive">
                                          <table class="table">
                                            <thead>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Captain</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>2</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Vice-Captain</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>1.5</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Being a part of the starting
                                                    XI
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>4</span>
                                                </td>
                                              </tr>
                                            </thead>
                                          </table>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="4">
                                    <Accordion.Header>
                                      Economy Rate Points
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="points-body">
                                        <div className="point-image">
                                          <img
                                            src={cricketPoint}
                                            alt="#"
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div class="table-responsive">
                                          <table class="table">
                                            <thead>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Minimum overs bowled by
                                                    player to be applicable
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>2</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>Below 5 runs per over</h6>
                                                </th>
                                                <td scope="col">
                                                  <span>6</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Between 5 and 5.99 runs per
                                                    over
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>4</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Between 6 and 7 runs per
                                                    over
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>2</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Between 10 and 11 runs per
                                                    over
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>-2</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Between 11.01 and 12 runs
                                                    per over
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>-4</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Above 12 runs per over
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>-6</span>
                                                </td>
                                              </tr>
                                            </thead>
                                          </table>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="5">
                                    <Accordion.Header>
                                      Strike Rate (Except Bowler) Points
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="points-body">
                                        <div className="point-image">
                                          <img
                                            src={cricketPoint}
                                            alt="#"
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div class="table-responsive">
                                          <table class="table">
                                            <thead>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Minimum balls faced by a
                                                    player to be applicable
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>10</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Between 60 and 70 runs per
                                                    100 balls
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>-2</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Between 50 and 59.99 runs
                                                    per 100 balls
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>-4</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Below 50 runs per 100 balls
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>-6</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Between 130 and 150 runs per
                                                    100 balls
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>2</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Between 150.01 and 170 runs
                                                    per 100 balls
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>4</span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th scope="col">
                                                  <h6>
                                                    Above 170 runs per 100 balls
                                                  </h6>
                                                </th>
                                                <td scope="col">
                                                  <span>6</span>
                                                </td>
                                              </tr>
                                            </thead>
                                          </table>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="6">
                                    <Accordion.Header>
                                      Other Important Points
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="points-body">
                                        <div class="points-data-content">
                                          <ul>
                                            <li>
                                              <strong>
                                                {" "}
                                                Rules about substitutes:
                                              </strong>
                                            </li>
                                            <li>
                                              All the X-Factor substitutes will
                                              get 4 points for being announced
                                              and will also score points for any
                                              contributions they make as per our
                                              Fantasy Point System.
                                            </li>
                                            <li>
                                              Concussion &amp; COVID-19
                                              Substitutes, if permitted as per
                                              the applicable rules and
                                              regulations of the tournament,
                                              will be awarded 4 points for
                                              making an appearance (only for
                                              T20s, ODs, Tests, T10s and The
                                              Hundred) and will be awarded
                                              points for all the contributions
                                              they make as per the Fantasy Point
                                              System.
                                            </li>
                                            <li>
                                              Substitutes other than Concussion
                                              substitutes, COVID-19 substitutes
                                              or X-Factor substitutes will not
                                              be awarded points for any
                                              contribution they make.
                                            </li>
                                            <li>
                                              In the Big Bash League, if a
                                              player gets replaced by an
                                              X-Factor substitute, but later
                                              comes back on field, he will get
                                              points for his contributions.
                                              However, if a player, who was not
                                              a part of the announced lineups,
                                              comes to the field as a
                                              substitute, he will not get points
                                              for any of his contributions
                                              (except Concussion and COVID-19
                                              substitutes).
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </div>
                            </Tab>
                          </Tabs>
                        </div>
                      </div>
                    </Row>
                  </Tab>
                  <Tab
                    eventKey="kabaddi"
                    title={
                      <span className="text-center">
                        <img
                          src={kabaddiicon}
                          width={60}
                          alt="icon"
                          className="img-fluid d-block tab_icon"
                        />
                        Kabaddi
                      </span>
                    }
                  >
                    <h2 className="text-center m-5" >Coming Soon</h2>
                  </Tab>
                  <Tab
                    eventKey="football"
                    title={
                      <span className="text-center">
                        <img
                          src={footballicon}
                          width={60}
                          alt="icon"
                          className="img-fluid d-block tab_icon"
                        />
                        Football
                      </span>
                    }
                  >
                    <h2 className="text-center m-5" >Coming Soon</h2>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default FantasyPointsSystem;
