import React from "react";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import {
  Navigation,
  Footer,
  Home,
  Cricket,
  Kabaddi,
  Football,
  About,
  Tearms,
} from "./components";

import ScrollToTop from "./ScrollToTop";
import ContactUs from "./components/ContactUs";
import Legality from "./components/Legality";
import ResponsiblePlay from "./components/ResponsiblePlay";
import RefundPolicy from "./components/RefundPolicy";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Disclaimer from './components/Disclaimer'
import Service  from './components/Service'
import ShippingDeliveryPolicy from './components/ShippingDeliveryPolicy'
import FantasyPointsSystem from "./components/FantasyPointsSystem";
import Howtoplay from "./components/how-to-play";
import AffiliateProgram from "./components/influencer/dashboard";
import Affilate from "./components/influencer/matches";
import EnteryView from "./components/influencer/contests";
import Earn from "./components/influencer/teams";
import Layout from "./Layout";
import Payment from "./components/Payment Page/Paymentpage";
import Withdraw from "./components/Withdraw";
import Airpay from "./components/Airpay";
import AirpayResponse from "./components/AirpayResponse"  ;


const App = () => {
  return (
    <>
      <Router>
        {/* <Navigation /> */}
        {/* <ScrollToTop> */}
        <Routes>
          <Route exact path="/*" element={<Layout><Home /></Layout>} />
          <Route path="/cricket" element={<Layout><Cricket /></Layout>} />
          <Route path="/kabaddi" element={<Layout><Kabaddi /></Layout>} />
          <Route path="/football" element={<Layout><Football /></Layout>} />
          <Route path="/about" element={<Layout><About /></Layout>} />
          <Route path="/terms-and-conditions" element={<Layout><Tearms /></Layout>} />
          <Route path="/contact" element={<Layout><ContactUs /></Layout>} />
          <Route path="/legality" element={<Layout><Legality /></Layout>} />
          <Route path="/responsible-play" element={<Layout><ResponsiblePlay /></Layout>} />
          <Route path="/refund-policy" element={<Layout><RefundPolicy /></Layout>} />
          <Route path="/privacy-policy" element={<Layout><PrivacyPolicy /></Layout>} />
          <Route path="/how-to-play" element={<Layout><Howtoplay /></Layout>} />
          <Route path="/disclaimer" element={<Layout><Disclaimer /></Layout>} />
          <Route path="/shipping-delivery-policy" element={<Layout><ShippingDeliveryPolicy /></Layout>} />
          <Route path="/service" element={<Layout><Service /></Layout>} />
          <Route path="/sabpaisa-callback" element={<Layout><h1> ok</h1></Layout>} />
          <Route path="/juspay-callback" element={<Layout><h1> ok</h1></Layout>} />
          <Route path="/upigateway-callback" element={<Layout><h1> ok</h1></Layout>} />
          <Route path="/airpay" element={<Layout><Airpay /></Layout>} />
          <Route path="/airpay-response" element={<Layout><AirpayResponse /></Layout>} />
          <Route path="/withdraw" element={<Withdraw />} />
          <Route
            path="/fantasy-points-system"
            element={<Layout><FantasyPointsSystem /></Layout>}
          />
          <Route
            path="/influencer-dashboard"
            element={<AffiliateProgram />}
          />
          <Route
            path="/influencer-matches"
            element={<Affilate />}
          />
          <Route
            path="/influencer-contests"
            element={<EnteryView />}
          />
          <Route
            path="/influencer-teams"
            element={<Earn />}
          />
          <Route
            path="/payment"
            element={<Payment />}
          />
          <Route
            path="/payment-done"
            element={<Payment />}
          />
        </Routes>
        {/* </ScrollToTop>
        <Footer /> */}
      </Router>
    </>
  );
};

export default App;
