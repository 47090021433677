import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import contact_img from "../assets/contactus.png";
import ContactForm from "./forms/ContactForm";



function ContactUs() {



  return (
    <>
      <section className="contactus aboutpage">
        <Container>
          {/* <Row>
            <div className="col-md-12">
              <div className="contact-heading text-center">
                <h1 className="mainheading">Contact Us</h1>
              </div>
              <div className="contact_img d-flex justify-content-center pb-5">
                <img
                  src={contact_img}
                  width={400}
                  alt="contact"
                  className="img-fluid"
                />
              </div>
            </div>
          </Row> */}
          <div className="py-2 py-md-5 contact__page">
            <Row className="mt-5">
              <Col>
                <div className="header-about pb-4">
                  <h1>
                    <strong>
                      Contact <span className="red-text">Us</span>
                    </strong>
                  </h1>
                  <p className="text-center">
                    <strong>
                      How does it feel to talk with a wall? Well, <br /> it’s
                      boring AF! Isn’t it? No worries, you have a partner here!
                    </strong>
                  </p>
                </div>
              </Col>
            </Row>

            <Row className="contactsectionArea">
              <Col xs={12} md={6} className="contactDetails order-2 order-md-1">
                <div className="upper">
                  <h4>
                    <strong>
                      So, why a second thought? <br /> Contact us today!
                    </strong>
                  </h4>
                  <p>
                    Our customer support team is just a call away to offer you
                    the seamless answer you expect!
                  </p>

                  <ul className="list-unstyled mt-4 contact_details_list">
                    <li className="list-item">
                      <i
                        className="bi bi-envelope-fill text-light"
                        style={{ fontSize: 24 }}
                      ></i>{" "}
                      <a href="mailto:support@dzire11.com" style={{ color: '#fff' }}>
                        support@dzire11.com
                      </a>
                    </li>
                    <li className="list-item">
                      <i
                        className="bi bi-geo-alt-fill text-light"
                        style={{ fontSize: 24 }}
                      ></i>
                      {/* <a href="#" style={{ color: '#fff' }}> Flat No 202, Block C, Gokuls Nandanam,  Simhapuri Colony, Bowrampet, Hyderabad 500043</a> */}
                      <a href="#" style={{ color: '#fff' }}> Bowrampet, Hyderabad 500043</a>
                    </li>
                    <li className="list-item">
                      <i
                        className="bi bi-telephone-fill text-light"
                        style={{ fontSize: 24 }}
                      ></i>
                      <a href="tel:+91-7032899909" style={{ color: '#fff' }}> +91-7032899909</a>
                    </li>
                  </ul>
                </div>

                <div className="lower">
                  <ul className="list-inline mt-4 social__icon__list socialkit_margin_top">
                    <li className="list-inline-item">
                      <a href="https://twitter.com/Dzire_11" style={{ fontSize: 30 }} target="_blank">
                        <i
                          className="bi bi-twitter text-light"
                        >
                        </i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="https://www.facebook.com/people/Dzire-App/pfbid0m3a7baXZkELjBXTPDnekMaS9REVqdGW9CmEYxahdm8uYccLDhPrPfsX2yJLuq5U4l/" style={{ fontSize: 30 }} target="_blank">
                        <i
                          className="bi bi-facebook text-light"
                          style={{ fontSize: 30 }}
                        ></i>
                      </a>


                    </li>
                    <li className="list-inline-item">

                      <a href="https://www.instagram.com/dzire._.11/" style={{ fontSize: 30 }} target="_blank">
                        <i
                          className="bi bi-instagram text-light"
                          style={{ fontSize: 30 }}
                        ></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <ContactForm />
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
}

export default ContactUs;
