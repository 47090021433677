import React from 'react';
import { Container, Row, Col, Accordion } from "react-bootstrap";

const faq__football = () => {
  return (
    <section className="faq__section py-5 faq__section_sub">
      <Container>
        <Row>
          <Col>
            <div className="section__title__area pt-5">
              <h2>FREQUENTLY ASKED QUESTIONS</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} className="p-md-2 p-lg-5">
            <Accordion >
              <Accordion.Item eventKey="0">
                <Accordion.Header>What is fantasy football?</Accordion.Header>
                <Accordion.Body>
                  In fantasy football, players take on the general manager role for a made-up football team. Each
                  team builds its roster from actual NFL players, and its score is determined by the player's
                  statistical output in games. Winning is determined by a team's point total relative to the other
                  teams in the league.

                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header> How can I become a part of a fantasy football team?
                </Accordion.Header>
                <Accordion.Body>
                  Finding a fantasy football league with open registration is the first step in becoming a part of
                  one. Once you've located a suitable league, the following steps are to join and pick a roster.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>. How do I set my lineup?</Accordion.Header>
                <Accordion.Body>
                  There is a weekly lineup decision to be made before each football game. Choosing your starting
                  lineup necessitates deciding who will join them from the bench. Your starting lineup's statistical
                  performance in actual football games will determine how many points your team receives.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header> How do I earn points in fantasy football?</Accordion.Header>
                <Accordion.Body>
                  In fantasy football, you score points based on how well your team's players statistically perform.
                  Points are awarded for several play categories, including touchdowns, interceptions, and yards.
                  The league commissioner or hosting website establishes the league's official scoring
                  regulations.
                </Accordion.Body>
              </Accordion.Item>

            </Accordion>
          </Col>
          <Col xs={12} md={6} className="p-md-2 p-lg-5">
            <Accordion >
              <Accordion.Item eventKey="4">
                <Accordion.Header>Can I make trades with other teams in my league?</Accordion.Header>
                <Accordion.Body>
                  Yes, it is common practice in fantasy football leagues to allow exchanges between teams. In this
                  scenario, one team sends another one either cash, or draught picks in return for the latter. Many
                  leagues have a commissioner or a website where trades must be approved before making
                  them.

                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="5">
                <Accordion.Header>How do I know if I win or lose in my fantasy football league?</Accordion.Header>
                <Accordion.Body>
                  Usually, you can check your fantasy football league's standings on their website or mobile app.
                  Each team's current record and the point total are displayed here. You can keep tabs on your
                  progress and see how you stack up against the competition with the help of these standings.

                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="6">
                <Accordion.Header>May I make lineup adjustments during the season?</Accordion.Header>
                <Accordion.Body>
                  In most cases, you can make roster adjustments midway through the season. Some examples
                  of this are picking up players from the waiver wire, negotiating deals with other teams, or
                  releasing underachieving players. Different leagues may have different regulations regarding
                  roster changes.

                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="7">
                <Accordion.Header>Can I earn money with Fantasy Football play in Dzire11?</Accordion.Header>
                <Accordion.Body>
                  Yes, you can make real money, provided you win the contest that you joined! You get the
                  winnings in your account, which takes some days due to verification purposes.
                </Accordion.Body>
              </Accordion.Item>

            </Accordion>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default faq__football