import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import About__img from "../assets/About__img.png";
import Teamimg from "../assets/team.png";

import Swal from "sweetalert2";

import { db } from "../firebaseConfig";
import { addDoc, collection } from "firebase/firestore";

import { useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone";

import Logo from "../assets/logo.png";
import ContactForm from "./forms/ContactForm";

const CATEGORIES = [
  "Cricket",
  "Kabaddi",
  "Football",
  "Payment",
  "Mobile APP",
  "Other",
];

const About = () => {
  const [isSubmitting, toggleSubmit] = React.useState(false);

  const userCollectionRef = collection(db, "contactdata");

  const formcontact = useFormik({
    initialValues: {
      fname: "",
      lname: "",
      email: "",
      phone: "",
      category: "",
      date: "",
      message: "",
    },
    validationSchema: Yup.object({
      phone: Yup.string().required("Required").phone("IN", true),

      category: Yup.string().required("Required"),

      fname: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),

      lname: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),

      email: Yup.string().email("Invalid email").required("Required"),

      date: Yup.date()
        .min(new Date(), "Please choose the future date")
        .required("Required"),

      message: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      toggleSubmit(!isSubmitting);

      addDoc(userCollectionRef, {
        First_Name: values["fname"],
        Last_Name: values["lname"],
        Email: values["email"],
        Contact_Number: values["phone"],

        Date: values["date"],
        Message: values["message"],
      })
        .then(() => {
          Swal.fire({
            position: "center",
            icon: "success",
            text: " We will reach you soon. Thanks!",
            title: "Received!",
            showConfirmButton: false,
            timer: 2000,
          });

          formcontact.resetForm();
          toggleSubmit(false);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        });
    },
  });

  return (
    <div className="aboutpage">
      <section>
        <Container>
          <Row>
            <Col>
              <div className="header-about">
                <h1 className="mainheading">About Us</h1>
                <h1>
                  {" "}
                  <strong>
                    Who <span className="red-text">we</span> are
                  </strong>{" "}
                </h1>
                <p>
                  Willing to turn your love for sports into real cash? Look no
                  further! Join Dzire11's fantasy platform and experience the
                  thrill of sports like never before! We at Dzire11 think sports
                  bring people together and build community. <br />
                  <br />
                  Your Sport, Our Passion! We've made a place where sports fans
                  world wide can connect and talk about their favorite teams and
                  players. We cater to sports lovers of all stripes by hosting
                  contests and events in fascinating games like Football,
                  Cricket, and Kabaddi! So, Win Big with Dzire11!
                </p>
                <img src={About__img} alt="" className="img-fluid mb-5" />
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col xs={12} md={6}>
              <div className="text-center px-4">
                <h1>
                  {" "}
                  <strong>
                    Our <span className="red-text">Mission</span>
                  </strong>{" "}
                </h1>
                <p>
                  At Dzire11, we strive to provide the best venue for fantasy
                  sports enthusiasts to demonstrate their talents and compete
                  for cash prizes. We don't, however, stop at the fundamentals!
                  Dzire11 is dedicated to empowering its customers by furnishing
                  them with the means to achieve their goals! So, Dzire11 offers
                  you everything under one roof!
                </p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="text-center px-4">
                <h1>
                  {" "}
                  <strong>
                    Our <span className="red-text">Vision</span>
                  </strong>{" "}
                </h1>
                <p>
                  Our goal is to provide the smoothest user experience! And we
                  don’t just say it; we mean it! You can never get off our
                  intuitive interfaces, lively discussion boards, and committed
                  support staff. We are constantly working to enhance and
                  diversify our selection to ensure you never run out of things
                  to try!
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="header-about">
                <img src={Teamimg} alt="" className="img-fluid mb-5" />
              </div>
            </Col>
          </Row>

          <Row className="py-3 py-md-5">
            <Col className="pt-2 pt-md-5">
              <div className="header-about">
                <h1>
                  {" "}
                  <strong>
                    Why Play <span className="red-text">With Us?</span>
                  </strong>{" "}
                </h1>
              </div>
            </Col>
          </Row>
          <Row className="details_card_rows pb-5">
            <Col sx={12} md={4} lg={3}>
              <div className="details_card">
                <h3 className="details_card_title">
                  {" "}
                  Numerous leagues and sports{" "}
                </h3>
                <p className="details_card_paragraph">
                  {" "}
                  You can join fantasy sports leagues for a wide range of sports
                  and leagues on Dzire11. Fans of football, cricket, and kabaddi
                  can find a league to suit their preferences.
                </p>
              </div>
            </Col>
            <Col sx={12} md={4} lg={3}>
              <div className="details_card">
                <h3 className="details_card_title">
                  Exciting contests and prizes
                </h3>
                <p className="details_card_paragraph">
                  Dzire11 is a great place to play fantasy sports, whether
                  you're a seasoned pro or just getting started. Everything on
                  Dzire11 is up for grabs, from daily fantasy contests to
                  tournaments.
                </p>
              </div>
            </Col>
            <Col sx={12} md={4} lg={3}>
              <div className="details_card">
                <h3 className="details_card_title">
                  {" "}
                  Standardized, Fair Scoring{" "}
                </h3>
                <p className="details_card_paragraph">
                  {" "}
                  You can rest assured that your team's performance will be
                  appropriately reflected in your point total because we use
                  accurate and up-to-date player stats to score our fantasy
                  leagues.
                </p>
              </div>
            </Col>
            <Col sx={12} md={4} lg={3}>
              <div className="details_card">
                <h3 className="details_card_title"> Win Real Cash</h3>
                <p className="details_card_paragraph">
                  {" "}
                  Who doesn’t love cash; when it is by playing your favourite
                  game? So, Live Your Fantasy Desire, and Earn Cash with
                  Dzire11!
                </p>
              </div>
            </Col>
            <Col sx={12} md={4} lg={3}>
              <div className="details_card">
                <h3 className="details_card_title"> Low Entry Fees </h3>
                <p className="details_card_paragraph">
                  {" "}
                  We believe in the affordability and exciting thrill of fantasy
                  sports. That’s why we offer a very nominal entry fee so that
                  you can enjoy your time playing fantasy sports!
                </p>
              </div>
            </Col>
            <Col sx={12} md={4} lg={3}>
              <div className="details_card">
                <h3 className="details_card_title"> Exciting Bonus </h3>
                <p className="details_card_paragraph">
                  {" "}
                  We know the happiness of getting bonus! Dzire11 offers you
                  100% bonus on your first deposit. And not only that, you get
                  referral bonus of 100 for every referral you get!
                </p>
              </div>
            </Col>
            <Col sx={12} md={4} lg={3}>
              <div className="details_card">
                <h3 className="details_card_title">
                  {" "}
                  Secure payment processing
                </h3>
                <p className="details_card_paragraph">
                  {" "}
                  To ensure the safety of your data, we only utilise secure,
                  conventional payment processing methods. So, feel confident
                  with Dzire11!
                </p>
              </div>
            </Col>
            <Col sx={12} md={4} lg={3}>
              <div className="details_card">
                <h3 className="details_card_title"> Customer service</h3>
                <p className="details_card_paragraph">
                  {" "}
                  Our dedicated customer support team is available 24/7 to
                  assist you with any queries or problems you may encounter
                  while using Dzire11.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row className="cta__about">
            <Col xs={12} md={8}>
              <div className="text-center text-md-start mb-4 mb-md-0">
                <h2>Call To Action</h2>
                <p>
                  So, why hold off? Dzire11 is your ticket to the excitement of
                  competition, the support of a dedicated sports community, and
                  the possibility of a life-changing prize! Come on, guys! Let’s
                  Gamify Our Passion.
                </p>
              </div>
            </Col>
            <Col xs={12} md={4} className="text-center text-md-end">
              <img src={Logo} alt="" className="img-fluid" />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="py-2 py-md-5 contact__page">
        <Container>
          <Row className="mt-5">
            <Col>
              <div className="header-about pb-4">
                <h1>
                  {" "}
                  <strong>
                    Contact <span className="red-text">Us</span>
                  </strong>{" "}
                </h1>
                <p>
                  {" "}
                  <strong>
                    How does it feel to talk with a wall? Well, <br /> it’s
                    boring AF! Isn’t it? No worries, you have a partner here!{" "}
                  </strong>{" "}
                </p>
              </div>
            </Col>
          </Row>

          <Row className="contactsectionArea">
            <Col xs={12} md={6} className="contactDetails order-2 order-md-1">
              <div className="upper">
                <h4>
                  {" "}
                  <strong>
                    So, why a second thought? <br /> Contact us today!
                  </strong>{" "}
                </h4>
                <p>
                  Our customer support team is just a call away to offer you the
                  seamless answer you expect!
                </p>

                <ul className="list-unstyled mt-4 contact_details_list">
                  <li className="list-item">
                    <i
                      className="bi bi-envelope-fill text-light"
                      style={{ fontSize: 24 }}
                    ></i>{" "}
                    <a href="mailto:support@dzire11.com" style={{ color: '#fff' }}>
                      support@dzire11.com
                    </a>
                  </li>
                  <li className="list-item">
                    <i
                      className="bi bi-geo-alt-fill text-light"
                      style={{ fontSize: 24 }}
                    ></i>
                    {/* <a href="#" style={{ color: '#fff' }}> Flat No 202, Block C, Gokuls Nandanam,  Simhapuri Colony, Bowrampet, Hyderabad 500043</a> */}
                    <a href="#" style={{ color: '#fff' }}> Bowrampet, Hyderabad 500043</a>
                  </li>
                  <li className="list-item">
                    <i
                      className="bi bi-telephone-fill text-light"
                      style={{ fontSize: 24 }}
                    ></i>
                    <a href="tel:+91-7032899909" style={{ color: '#fff' }}> +91-7032899909</a>
                  </li>
                </ul>
              </div>

              <div className="lower">
                <ul className="list-inline mt-4 social__icon__list socialkit_margin_top">
                  <li className="list-inline-item">
                    <a
                      href="https://twitter.com/Dzire_11"
                      style={{ fontSize: 30 }}
                      target="_blank"
                    >
                      <i className="bi bi-twitter text-light"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.facebook.com/people/Dzire-App/pfbid0m3a7baXZkELjBXTPDnekMaS9REVqdGW9CmEYxahdm8uYccLDhPrPfsX2yJLuq5U4l/"
                      style={{ fontSize: 30 }}
                      target="_blank"
                    >
                      <i
                        className="bi bi-facebook text-light"
                        style={{ fontSize: 30 }}
                      ></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.instagram.com/dzire._.11/"
                      style={{ fontSize: 30 }}
                      target="_blank"
                    >
                      <i
                        className="bi bi-instagram text-light"
                        style={{ fontSize: 30 }}
                      ></i>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <ContactForm />
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default About;
