import React from "react";
import howtoplay_1 from "../../src/assets/howtoplay_1.png";
import howtoplay_2 from "../../src/assets/howtoplay_2.png";
import howtoplay_3 from "../../src/assets/howtoplay_3.png";
import howtoplay_4 from "../../src/assets/howtoplay_4.png";
import howtoplay_5 from "../../src/assets/howtoplay_5.png";

function Howtoplay() {
  return (
    <>
      <section className="aboutpage howtoplay">
        <div className="container">
          <div class="row">
            <div class="col-md-12">
              <div class="header-privacy">
                <h1 class="text-center">
                  <strong>How To Play</strong>
                </h1>
              </div>
            </div>
          </div>
          <div className="steps_row">
            <div className="row align-items-center flex-md-row-reverse">
              <div className="col-md-5 step_content_right">
                <div className="step_content">
                  <div className="steps_number">01</div>
                  <h3>SELECT A MATCH</h3>
                  <p>
                    Choose the match you want to play and show-off your skills.
                  </p>
                </div>
              </div>
              <div className="col-md-7">
                <div className="step_image">
                  <img
                    className="img-fluid"
                    src={howtoplay_1}
                    width={360}
                    height={462}
                    alt="img"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="steps_row">
            <div className="row align-items-center">
              <div className="col-md-5 step_content_left">
                <div className="step_content">
                  <div className="steps_number left_number">02</div>
                  <h3>SELECT YOUR PLAYING XI</h3>
                  <p>
                    Create your own team by selecting different players within a
                    defined virtual credit points.
                  </p>
                </div>
              </div>
              <div className="col-md-7">
                <div className="step_image">
                  <img
                    className="img-fluid"
                    src={howtoplay_2}
                    width={360}
                    height={462}
                    alt="jk"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="steps_row">
            <div className="row align-items-center flex-md-row-reverse">
              <div className="col-md-5 step_content_right">
                <div className="step_content">
                  <div className="steps_number">03</div>
                  <h3>CHOOSE YOUR CAPTAIN & VICE CAPTAIN</h3>
                  <p>
                    Once you choose your playing 11 it’s time to choose your
                    captain & vice-captain that will help you earn extra points.
                  </p>
                  <p>
                    2x points for the Captain & 1.5x points for the Vice-Captain
                    based on their on-field performance.
                  </p>
                </div>
              </div>
              <div className="col-md-7">
                <div className="step_image">
                  <img
                    className="img-fluid"
                    src={howtoplay_3}
                    width={360}
                    height={462}
                    alt="img"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="steps_row">
            <div className="row align-items-center">
              <div className="col-md-5 step_content_left">
                <div className="step_content">
                  <div className="steps_number left_number">04</div>
                  <h3>ONE LEAGUE, MULTIPLE TEAMS</h3>
                  <p>
                    Increase your winning chances with Multiple Entries. Join a
                    “Multiple Entry” League with a maximum of 15 different teams
                    T&C Apply.
                  </p>
                </div>
              </div>
              <div className="col-md-7">
                <div className="step_image">
                  <img
                    className="img-fluid"
                    src={howtoplay_4}
                    width={360}
                    height={462}
                    alt="jk"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="steps_row">
            <div className="row align-items-center flex-md-row-reverse">
              <div className="col-md-5 step_content_right">
                <div className="step_content">
                  <div className="steps_number">05</div>
                  <h3>BECOME A PART OF A LEAGUE</h3>
                  <p>
                    With your playing XI join a league or contest organized by
                    MYMASTER11 to compete with other users or compete with your
                    friends through a private league created by you or your
                    friends.
                  </p>
                </div>
              </div>
              <div className="col-md-7">
                <div className="step_image">
                  <img
                    className="img-fluid"
                    src={howtoplay_5}
                    width={360}
                    height={462}
                    alt="img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Howtoplay;
