import React from 'react'

function Service() {
  return (
    <>
        <section className='aboutpage service_sec'>
            <div className="container">
            <div class="row">
            <div class="col-md-12">
              <div class="header-privacy">
                <h1 class="text-center">
                  <strong>Service / Contest Page </strong>
                </h1>
                <p>
                Welcome to Dzire 11: Where Fantasy Sports Dreams Come True!
                </p>
                <p>
                Get ready to embark on an extraordinary adventure in fantasy sports! At Dzire 11, we've created a platform that transcends the ordinary, offering an immersive experience filled with thrilling contests and a vibrant community of like-minded sports enthusiasts. 
                </p>
                <p>
                It's time to unleash your competitive spirit and witness the magic that unfolds when passion meets opportunity. Experience the thrill, excitement, and rewards at Dzire 11.
                </p>
              </div>
            </div>
            </div>
            <div class="row">
            <div class="col-md-12">
              <div class="content pb-4">
                <h3>
                  <strong>4 Sports 1 Winner Contest: Ignite Your Sporting Journey!</strong>
                </h3>
                <hr />
                <p>
                <h5 className='text-black'><b>Unleash your versatility: </b></h5>
                Dzire11 is an online fantasy sports platform; thus, there is no physical shipping or delivery of goods. All transactions, including deposits and withdrawals, are conducted digitally through the website or mobile application.
                </p>
                <p>
                <h5 className='text-black'><b>Unmatched affordability:  </b></h5>
                With an entry fee starting at just 79 Rs, Dzire 11 offers an incredible opportunity to experience the adrenaline rush of fantasy sports without breaking the bank. Leave behind the days of excessive charges, as other platforms charge as high as 89 Rs for similar contests!
                </p>
                <p>
                <h5 className='text-black'><b>Elevate your winnings:  </b></h5>
                Prepare for a rewarding journey as you compete for a remarkable winning amount of 300 Rs. But that's not all. Dzire 11 takes your excitement to new heights with entry charges of up to 2576 Rs, unlocking the potential to win a jaw-dropping 10,000 Rs! It's time to turn your passion into substantial rewards.
                </p>
              </div>
            </div>
            </div>
            <div class="row">
            <div class="col-md-12">
              <div class="content pb-4">
                <h3>
                  <strong>2 Member Contest: Power of Partnership!</strong>
                </h3>
                <hr />
                <p>
                <h5 className='text-black'><b>Team up for greatness: </b></h5>
                Join forces with a partner and let the power of collaboration fuel your journey to victory. In our 2-Member Contest, the thrill of competing is elevated by the joy of shared achievements, where every win celebrates teamwork.
                </p>
                <p>
                <h5 className='text-black'><b>Unbeatable savings:  </b></h5>
                At Dzire 11, we understand the value of your hard-earned money. That's why our entry fee starts at just 510 Rs, offering significant savings compared to other platforms that demand a hefty amount. With Dzire 11, you can save at least 12-15% Rs, which means more opportunities to participate in exciting contests.
                </p>
                <p>
                <h5 className='text-black'><b>Unleash your winning potential: </b></h5>
                The prizes await and are nothing short of dazzling. From an impressive 1000 Rs to a staggering 10,000 Rs, the rewards are within your grasp. Together with your partner, set your sights on victory and reap the fruits of your combined efforts.
                </p>
              </div>
            </div>
            </div>
            <div class="row">
            <div class="col-md-12">
              <div class="content pb-4">
                <h3>
                  <strong>3 Sports 1 Winner Contest: Conquer the Triple Threat!</strong>
                </h3>
                <hr />
                <p>
                <h5 className='text-black'><b>Embrace the ultimate challenge:  </b></h5>
                Are you ready to test your skills across three captivating sports? The 3 Sports 1 Winner Contest is your chance to showcase your versatility and prove that you can dominate the field in more ways than one.
                </p>
                <p>
                <h5 className='text-black'><b>Accessible entry fees:  </b></h5>
                We believe in inclusivity, which is why our entry fee for this thrilling contest starts at just 343 Rs which is at least 15% more affordable than others! Dzire 11 ensures that the world of fantasy sports is open to all, irrespective of their budget.
                </p>
                <p>
                <h5 className='text-black'><b>
                    Triumph in glory: </b></h5>
                    The road to victory leads to astounding prizes ranging from 1000 Rs to an awe-inspiring 10,000 Rs. With each sport conquered, your winning potential grows, allowing you to claim your place at the top of the leaderboard.
                </p>
              </div>
            </div>
            </div>
            <div class="row">
            <div class="col-md-12">
              <div class="content pb-4">
                <h3>
                  <strong>Why Choose Dzire 11?</strong>
                </h3>
                <hr />
                <p>
                <h5 className='text-black'><b>Unparalleled Variety & Rewards:  </b></h5>
                Experience the thrill of multiple sports under one roof. With kabaddi, cricket, and football at your fingertips, Dzire 11 ensures never-dull moments. Not only about varieties; you get rewards too! With generous cash prizes ranging from 1000 Rs to an astonishing 10,000 Rs, Dzire 11 offers unparalleled rewards!
                </p>
                <p>
                <h5 className='text-black'><b>Cutting-edge technology:   </b></h5>
                Our advanced platform provides a seamless, user-friendly experience, ensuring that you can focus on what matters most: competing, strategizing, and winning.
                </p>
                <p>
                <h5 className='text-black'><b>Fair play and transparency:  </b></h5>
                At Dzire 11, we prioritize integrity and fair competition. Our contests are carefully crafted to maintain a level playing field for all participants, ensuring that skill and knowledge are the true determinants of success.
                </p>
                <p>
                <h5 className='text-black'><b>Thriving community:  </b></h5>
                Join a vibrant community of passionate sports enthusiasts who share your love for fantasy sports. Engage in discussions, exchange strategies, and celebrate victories together. The camaraderie and support of fellow enthusiasts make the journey all the more rewarding.
                </p>
                <p>So, what are you waiting for? Don't let your sporting dreams be confined to the sidelines!
                    Choose Dzire 11 & Unlock Your Potential at Dzire 11 Today!
                    </p>
              </div>
            </div>
            </div>
            </div>
        </section>
    </>
  )
}

export default Service