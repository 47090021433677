import React from "react";
import { Container, Row } from "react-bootstrap";

function PrivacyPolicy() {
  return (
    <>
      <section className="privacy aboutpage">
        <Container>
          <Row>
            <div className="col-md-12">
              <div className="header-privacy">
                <h1 className="text-center">
                  <strong>Privacy Policy</strong>
                </h1>
                <p>
                  Protecting our users' personal information is our top priority
                  here at dzire11. If you use the dzire11 website or any of
                  the services or goods offered on the website, then this
                  Privacy Policy will explain how we collect, use, and disclose
                  your personal information.
                </p>
                <p>
                  Your use of the dzire11 website constitutes your acceptance
                  of this Privacy Policy and your consent to the collection,
                  use, and sharing of your personal information in the ways
                  specified herein. Do not use the dzire11 website if you do
                  not accept our standards and procedures.
                </p>
              </div>
            </div>
          </Row>
          <Row className=" justify-content-center">
            <div className="col-md-12">
              <div className="content pb-4">
                <h3>
                  <strong>Information We Collect</strong>
                </h3>
                <hr />
                <p>
                  When you use the dzire11 website or sign up for one of the
                  site's many contests or games, we will collect some personal
                  information from you. The following are examples of what this
                  data might contain:
                </p>
              </div>
              <div className="privacy_mini">
                <ul>
                  <li>
                    Details about yourself include your name, address, and
                    contact information.
                  </li>
                  <li>
                    To complete a transaction on the dzire11 website, we need
                    financial information such as your credit card number or
                    bank account details.
                  </li>
                  <li>
                    Details about your visit to the dzire11 website, including
                    your IP address and pages viewed.
                  </li>
                  <li>
                    Personal data you give us when you get in touch with us, as
                    in an email or through the website's "Contact Us" form.
                  </li>
                </ul>
                <p>
                  If you connect your dzire11 account to a social media
                  platform, we can access{" "}
                  <b>certain information about you from that account.</b>
                </p>
              </div>
            </div>
          </Row>
          <Row className=" justify-content-center">
            <div className="col-md-12">
              <div className="content pb-4">
                <h3>
                  <strong>Use of Your Individual Data</strong>
                </h3>
                <hr />
                <p>Use of Your Individual Data -</p>
              </div>
              <div className="privacy_mini">
                <ul>
                  <li>
                    To give you access to the dzire11 website and its features,
                    such as the ability to enter contests and play games.
                  </li>
                  <li>
                    To carry out and finalize any financial transactions
                    initiated by you on the dzire11 website, such as the
                    collection of entry fees or the distribution of prize money.
                  </li>
                  <li>
                    To inform you of account changes, contests you've entered,
                    and other important information related to the dzire11
                    website and services.
                  </li>
                  <li>
                    To tailor the dzire11 website to the individual user by
                    displaying material and adverts tailored to the individual's
                    preferences.
                  </li>
                </ul>
                <p>
                  As part of our efforts to enhance the dzire11 website and the
                  services we offer, we regularly conduct analyses of user data
                  to learn more about the site's visitors and how the site may
                  be optimized.
                </p>
              </div>
            </div>
          </Row>
          <Row className=" justify-content-center">
            <div className="col-md-12">
              <div className="content pb-4">
                <h3>
                  <strong>Disclosure of Your Personal Information</strong>
                </h3>
                <hr />
                <p>
                  For the reasons below, we may share your personal information
                  with other parties:
                </p>
              </div>
              <div className="privacy_mini">
                <ul>
                  <li>
                    By third-party service providers, such as credit card
                    processors, who help us provide the items and services
                    advertised on the dzire11 website to you.
                  </li>
                  <li>
                    Third parties, such as advertisers and marketing partners,
                    so that they can provide you with more relevant and engaging
                    adverts.
                  </li>
                  <li>
                    To the appropriate governmental or law enforcement
                    authorities if we determine that such disclosure is required
                    by law or is otherwise necessary to protect the rights,
                    property, or safety of dzire11, our users, or the general
                    public
                  </li>
                </ul>
                <p>
                  Your information may also be shared if we are involved in a
                  <b>
                    business transaction such as a merger, acquisition, or
                    selling of assets.
                  </b>
                </p>
              </div>
            </div>
          </Row>
          <Row className=" justify-content-center">
            <div className="col-md-12">
              <div className="content pb-4">
                <h3>
                  <strong>Cookies and Other Tracking Technologies</strong>
                </h3>
                <hr />
                <p>
                  Information about your visit to the dzire11 website is
                  gathered through tracking technology such as cookies. When you
                  visit a website, it may{" "}
                  <b>store a small text file on your device as a cookie.</b>{" "}
                  Cookies let us analyze traffic patterns on the dzire11 site
                  to improve your visit. Disabling cookies in your browser
                  settings may prevent you from using some parts of the website.
                </p>
              </div>
            </div>
          </Row>
          <Row className=" justify-content-center">
            <div className="col-md-12">
              <div className="content pb-4">
                <h3>
                  <strong>Safety of Information</strong>
                </h3>
                <hr />
                <p>
                  We store all collected data in a secure environment to prevent
                  unauthorized access, use, or disclosure. The security of your
                  personal information is important to us, but remember that no
                  method of communication over the internet or
                  <b>electronic storage method is 100% safe.</b>
                </p>
              </div>
            </div>
          </Row>
          <Row className=" justify-content-center">
            <div className="col-md-12">
              <div className="content pb-4">
                <h3>
                  <strong>Safeguarding Children's Confidentiality</strong>
                </h3>
                <hr />
                <p>
                  We <b>cannot allow anyone under 18</b>
                  on the dzire11 website for legal reasons. Information from
                  minors under the age of 18 is never intentionally collected.
                  To protect children's privacy, if we learn that we ha
                </p>
              </div>
            </div>
          </Row>
          <Row className=" justify-content-center">
            <div className="col-md-12">
              <div className="content pb-4">
                <h3>
                  <strong>Alterations to the Privacy Policy</strong>
                </h3>
                <hr />
                <p>
                  This Privacy Statement may be revised periodically to{" "}
                  <b>reflect changes in our practices</b> or for other business,
                  legal, or regulatory requirements. Please check back
                  frequently to see if the Privacy Policy has been updated.
                </p>
              </div>
            </div>
          </Row>
          <Row className=" justify-content-center">
            <div className="col-md-12">
              <div className="content pb-4">
                <h3>
                  <strong>Contact Information</strong>
                </h3>
                <hr />
                <p>
                  If you have any questions or concerns about these terms and
                  conditions or the dzire11 website, please contact us at <a href="mailto:support@dzire11.com">support@dzire11.com</a>
                </p>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default PrivacyPolicy;
