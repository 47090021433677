import React from "react";

import { CTAK } from "../components";
import { FAQkabaddi } from "../components";

import { Container, Row, Col } from "react-bootstrap";
import kabaddi_main from '../assets/kabaddi-main.png';
import kabaddi_mobile from '../assets/kabaddi_mobile.png';

import step__1__img from '../assets/step__1.png';
import step__2__img from '../assets/step__2.png';
import step__3__img from '../assets/step__3.png';
import step__4__img from '../assets/step__4.png';
import step__5__img from '../assets/step__5.png';

function Kabaddi() {
  return (

    <>
      <section className="cricket_sec" >
        <Container>
          <Row className="py-5">
            <Col className="text-center">
              <h1> <strong>Fantasy Kabaddi Playing Guide</strong> </h1>
            </Col>
          </Row>

          {/* Desktop Layout*/}

          <Row className="pb-5 d-none d-lg-flex">
            <Col xs={12} md={5} lg={6} className="d-flex justify-content-center align-items-center">
              <img className="img-fluid py-5" src={kabaddi_main} alt="" />
            </Col>
            <Col xs={12} md={7} lg={6} className="d-flex flex-column justify-content-center align-items-center">

              <div className="step__box step__1_box">
                <h4>Select your match</h4>
                <p>Before putting together your fantasy kabaddi team, decide which match you'd like to play. Keep
                  in mind the timelines for choosing the match. Try to join as soon as it goes live!</p>
              </div>
              <div className="step__box step__2_box">
                <h4>Create a squad</h4>
                <p>Build a kabaddi squad that showcases your best players. Keep the team's overall composition
                  and dynamic in mind when making your choices</p>
              </div>
              <div className="step__box step__3_box">
                <h4>Choose your captain and vice-captain</h4>
                <p>The captain and vice-captain of your fantasy kabaddi team will earn bonus points! So pick the
                  best here because they can significantly impact your squad's performance.</p>
              </div>
              <div className="step__box step__4_box">
                <h4>Join Contest</h4>
                <p>Join the contest that you want to participate in. We have many contests with various timing, and
                  you can choose one based on your skill.</p>
              </div>
              <div className="step__box step__5_box">
                <h4>Keep track of your winnings</h4>
                <p>You should always know where you stand in your fantasy kabaddi league. Check how others
                  are performing and what to do next.</p>
              </div>

            </Col>
          </Row>

          {/* Mobile Layout */}
          <div className="d-block d-lg-none pb-5">
            <Row>
              <Col className="text-center pb-5">
                <img src={kabaddi_mobile} alt="" />
              </Col>
            </Row>

            <Row className="step__box_item">
              <Col xs={2} sm={2} className="d-flex justify-content-center align-items-center">
                <img src={step__1__img} alt="" />
              </Col>
              <Col xs={10} sm={10}>
                <div className="step__box step__1_box">
                  <h4>Select your match</h4>
                  <p>Before putting together your fantasy kabaddi team, decide which match you'd like to play. Keep
                    in mind the timelines for choosing the match. Try to join as soon as it goes live!
                  </p>
                </div>
              </Col>
            </Row>

            <Row className="step__box_item">
              <Col xs={2} sm={2} className="d-flex justify-content-center align-items-center">
                <img src={step__2__img} alt="" />
              </Col>
              <Col xs={10} sm={10}>
                <div className="step__box step__2_box">
                  <h4>Create a squad</h4>
                  <p>Build a kabaddi squad that showcases your best players. Keep the team's overall composition
                    and dynamic in mind when making your choice</p>
                </div>
              </Col>
            </Row>

            <Row className="step__box_item">
              <Col xs={2} sm={2} className="d-flex justify-content-center align-items-center">
                <img src={step__3__img} alt="" />
              </Col>
              <Col xs={10} sm={10}>
                <div className="step__box step__3_box">
                  <h4>Choose your captain and vice-captain</h4>
                  <p>The captain and vice-captain of your fantasy kabaddi team will earn bonus points! So pick the
                    best here because they can significantly impact your squad's performance.</p>
                </div>
              </Col>
            </Row>

            <Row className="step__box_item">
              <Col xs={2} sm={2} className="d-flex justify-content-center align-items-center">
                <img src={step__4__img} alt="" />
              </Col>
              <Col xs={10} sm={10}>
                <div className="step__box step__4_box">
                  <h4>Join Contest</h4>
                  <p>Join the contest that you want to participate in. We have many contests with various timing, and
                    you can choose one based on your skill.</p>
                </div>
              </Col>
            </Row>

            <Row className="step__box_item">
              <Col xs={2} sm={2} className="d-flex justify-content-center align-items-center">
                <img src={step__5__img} alt="" />
              </Col>
              <Col xs={10} sm={10}>
                <div className="step__box step__5_box">
                  <h4>Keep track of your winnings</h4>
                  <p>You should always know where you stand in your fantasy kabaddi league. Check how others
                    are performing and what to do next.</p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>

      <section className="tips__section">
        <Container>
          <Row className="mb-md-5 mb-4 text-center text-md-start">
            <Col>
              <h2>Fantasy Kabaddi Playing <span className="red-text">Tips</span></h2>

            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <div className="single__tip">
                <p>Analyze and evaluate the players! You must know their strengths and flaws to pick the
                  right players for your fantasy team. Evaluate how they have performed in the past.</p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="single__tip">
                <p>Pick a squad with a healthy mix of skills. You should have a little of each on board to
                  increase your team's chances of winning.</p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="single__tip">
                <p>Keep an eye on the schedule. Some clubs may have a more manageable schedule than
                  others, with a shorter span between games. This may increase their scoring potential.
                </p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="single__tip">
                <p>Consider the matchups. Choose offensive players if your defence is good, yet you're
                  playing a team with a strong offence. Conversely, if a team's offence isn't very good, but
                  they're playing a club with a similarly poor defence, selecting players from the defensive
                  team might be prudent.
                </p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="single__tip">
                <p>Consider alternatives. It's crucial to have a strong group of backup players available in
                  case of injuries or other absences</p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="single__tip">
                <p>Check out the latest team updates. Follow the team's news and any changes to your
                  favourite players' playing status. This could negatively impact your fantasy team's
                  performance.
                </p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="single__tip">
                <p>Spend money prudently. There is a limit on how much money you can spend on players
                  in each fantasy league, so you must assemble the best possible team for the least
                  amount of money</p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="single__tip">
                <p>It's okay to shake things up. Feel free to test a new player if you discover one of your
                  current selections is not meeting your expectations.</p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="single__tip">
                <p>Don't waste your opportunity to lead. The captain of your team in a fantasy kabaddi
                  league receives double points for goals scored by their team. Picking this guy wisely can
                  significantly impact how well your team does</p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="single__tip">
                <p>Enjoy yourself. Fantasy sports are merely a game; like any other game, they should be
                  played for fun. Have fun, and don't worry about how you stack up against the other
                  players.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <CTAK />
      <FAQkabaddi />
    </>

  );
}

export default Kabaddi;