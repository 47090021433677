import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import footer_icon_1 from "../assets/gavel.svg";
import footer_icon_2 from "../assets/headset.png";
import footer_icon_3 from "../assets/hand.png";
import footer_icon_4 from "../assets/money.svg";
import footer_icon_5 from "../assets/award.svg";
import phonepe from "../assets/phonepe.svg"
import logo from "../assets/logo.png";
import fb from "../assets/fb.png";
import tw from "../assets/tw.png";
import ins from "../assets/ins.png";
import yt from "../assets/yt.png";
import { Link, useLocation } from "react-router-dom";
import subpisa from "../assets/sabpaisa-logo.png";
import Razorpay_logo from "../assets/Razorpay_logo.svg";
import airpay from "../assets/airpay.svg";
import cashfree from "../assets/Cashfree.svg"
import env from "../config/env";

function Footer() {
  const { pathname } = useLocation()

  if (env.noLayoutRoutes.includes(pathname)) {
    return <></>
  }
  return (
    <>
      <footer className="footer pt-5">
        <Container>
          <Row className="pt-3 pb-5 pt-md-5 pb-md-2 border-area">
            <Col
              xs={12}
              md={6}
              className="d-flex justify-content-center align-items-center justify-content-md-start align-items-md-center"
            >
              <h2 className="text-light">
                Best fantasy sports <br /> platform 💪 in india.
              </h2>
            </Col>
          </Row>

          {/* <Row className="pt-3 pb-3 pt-md-5 pb-md-5">
            <Col xs={12} md={12} lg={6} className="mb-5 mb-md-5 mb-lg-0 text-center text-md-start">
              <h5 className="text-light mb-4">About Dzire11</h5>
              <p className="text-light footer-paragraph">We are a group of tech geeks and sports fans that enjoy the thrill of competition. Dzire11
                allows you to select fantasy teams, join leagues and tournaments, and compete head-to-head in
                various skill, knowledge, and strategy-based challenges for rewards and bragging rights.</p>
              <ul className="list-inline mt-4 social__icon__list d-none d-md-block">
              <li className="list-inline-item">
                  <a href={twitter__link}><i className="bi bi-twitter text-light" style={{ fontSize: 20 }}></i></a>
                </li>
                <li className="list-inline-item">
                  <a href={facebook__link}><i className="bi bi-facebook text-light" style={{ fontSize: 20 }}></i></a>
                </li>
                <li className="list-inline-item">
                  <a href={instagram__link}><i className="bi bi-instagram text-light" style={{ fontSize: 20 }}></i></a>
                </li>   
              </ul>
            </Col>
            <Col xs={12} md={4} lg={2} className="text-center text-md-start">
              <h5 className="text-light mb-4">Company</h5>
              <ul className="list-unstyled">
                <li className="list-item">
                  <Link to="/about" className="nav-link">About</Link>
                </li>
                <li className="list-item">
                  <Link to="/fantasy" className="nav-link">Fantasy</Link>
                </li>
                <li className="list-item">
                  <Link to="/games" className="nav-link">Games</Link>
                </li>
              </ul>
            </Col>
            <Col xs={12} md={4} lg={2} className="text-center text-md-start">
              <h5 className="text-light mb-4">Fantasy</h5>
              <ul className="list-unstyled">
                <li className="list-item">
                  <Link to="/points-table" className="nav-link">Points Table</Link>
                </li>
                <li className="list-item">
                  <Link to="/rules-and-regulation" className="nav-link">Rules & Regulation</Link>
                </li>
                <li className="list-item">
                  <Link to="/leader-board" className="nav-link">Leader Board</Link>
                </li>
                <li className="list-item">
                  <Link to="/referral" className="nav-link">Referral</Link>
                </li>
              </ul>
            </Col>
            <Col xs={12} md={4} lg={2} className="text-center text-md-start">
              <h5 className="text-light mb-4">Help</h5>
              <ul className="list-unstyled">
                <li className="list-item">
                  <Link to="/customer-support" className="nav-link">Customer Support</Link>
                </li>
                <li className="list-item">
                  <Link to="/payment-details" className="nav-link">Payment Details</Link>
                </li>
                <li className="list-item">
                  <Link to="/terms-and-conditions" className="nav-link">Terms & Conditions</Link>
                </li>
                <li className="list-item">
                  <Link to="/privacy-policy" className="nav-link">Privacy Policy</Link>
                </li>
              </ul>

              <ul className="list-inline mt-4 social__icon__list d-block d-md-none">

                <li className="list-inline-item">
                  <a href={twitter__link}><i className="bi bi-twitter text-light" style={{ fontSize: 20 }}></i></a>
                </li>
                <li className="list-inline-item">
                  <a href={facebook__link}><i className="bi bi-facebook text-light" style={{ fontSize: 20 }}></i></a>
                </li>
                <li className="list-inline-item">
                  <a href={instagram__link}><i className="bi bi-instagram text-light" style={{ fontSize: 20 }}></i></a>
                </li>
              </ul>
            </Col>

          </Row> */}
          <Row>
            <div className="row row-cols-5 justify-content-center header-row-ft border-area">
              <div className="col">
                <div className="footer-heads-card">
                  <div className="icon-card">
                    <div className="icon-in">
                      <img
                        src={footer_icon_1}
                        alt="icon"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="content">
                    <h6>100% Legal &amp; Secure</h6>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="footer-heads-card">
                  <div className="icon-card">
                    <div className="icon-in">
                      <img
                        src={footer_icon_2}
                        alt="icon"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="content">
                    <h6>24x 7 Customer Support</h6>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="footer-heads-card">
                  <div className="icon-card">
                    <div className="icon-in">
                      <img
                        src={footer_icon_3}
                        alt="icon"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="content">
                    <h6>Strict Fairplay Policies</h6>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="footer-heads-card">
                  <div className="icon-card">
                    <div className="icon-in">
                      <img
                        src={footer_icon_4}
                        alt="icon"
                        className="img-fluid"
                      />
                      <i className="fas fa-money-bill-alt"></i>
                    </div>
                  </div>
                  <div className="content">
                    <h6>Instant Withdrawals</h6>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="footer-heads-card">
                  <div className="icon-card">
                    <div className="icon-in">
                      <img
                        src={footer_icon_5}
                        alt="icon"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="content">
                    <h6>Exciting Cashbonus Offers</h6>
                  </div>
                </div>
              </div>
            </div>
          </Row>
          <Row className=" ft_row footer-main">
            <div className="col-md-3 col-12">
              <div className="footer-logo">
                <a className="navbar-brand" href="/term-conditions#">
                  <img src={logo} className="img-fluid w-100" alt="logo" />
                </a>
                <ul className="contact_ul">
                  <li>
                    <a href="mailto:support@dzire11.com">
                      <i
                        className="bi bi-envelope-fill text-light"
                        style={{ fontSize: 24 }}
                      ></i>{" "}
                      support@dzire11.com
                    </a>
                  </li>
                  <li>
                    <a href="tel:+91-7032899909">
                      <i
                        className="bi bi-telephone-fill text-light"
                        style={{ fontSize: 24 }}
                      ></i>{" "}
                      +91-7032899909
                    </a>
                  </li>
                </ul>
              </div>
              <div className="ft-right-bar">
                <ul className="social_ul">
                  <li>
                    <Link to="https://www.facebook.com/people/Dzire-App/pfbid0m3a7baXZkELjBXTPDnekMaS9REVqdGW9CmEYxahdm8uYccLDhPrPfsX2yJLuq5U4l/">
                      <img src={fb} className="img-fluid" alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://twitter.com/Dzire_11">
                      <img src={tw} className="img-fluid" alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.instagram.com/dzire._.11/">
                      <img src={ins} className="img-fluid" alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <img src={yt} className="img-fluid" alt="" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="links">
                <h4>Quick Links</h4>
                <ul className="links-ul">
                  <li>
                    <Link to="/about" className="nav-link">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/legality">Legality</Link>
                  </li>
                  <li>
                    <Link to="/responsible-play">Responsible Play</Link>
                  </li>
                  <li>
                    <Link to="/terms-and-conditions">
                      Terms &amp; Conditions
                    </Link>
                  </li>
                  <li>
                    <Link to="/refund-policy">Refund Policy</Link>
                  </li>
                  <li>
                    <Link to="/disclaimer">Disclaimer</Link>
                  </li>
                  <li>
                    <Link to="/shipping-delivery-policy">Shipping Delivery Policy</Link>
                  </li>
                  <li>
                    <Link to="/service">Service / Contest</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="links">
                <h4>Our Services</h4>
                <ul className="links-ul">
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/how-to-play">How To Play</Link>
                  </li>
                  <li>
                    <Link to="/fantasy-points-system">
                      Fantasy Points System
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="links">
                <h4>Payment Partners</h4>
                <div className="payments-partner ">
                <div className="payment_logo">
                  {/* <img src={subpisa} alt="#" className="img-fluid" /> */}
                  <img src={cashfree} alt="#" className="img-fluid " />
                  <img src={airpay} alt="#" className="img-fluid  ms-xl-3" />
                </div>
                <div className="payment_logo mt-xl-3">
                  <img src={Razorpay_logo} alt="#" className="img-fluid" />
                </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
        <div className="footer-bars-main">
          <div className="container">
            <Row className="align-items-center">
              <div className="col-md-12">
                <div className="footer_bar">
                  <div className="ft-left-bar">
                    <p>
                      <span className="span-copy">
                        Copyright © 2024 &nbsp;
                        <Link className="text-warning text-decoration-underline" to="/">
                        @Dzire11.com 
                        </Link>
                      </span>
                      &nbsp;  Design & Developed By  &nbsp;
                        <Link className="text-warning text-uppercase text-decoration-underline" to="/ty">
                        Dzire11 Fantasy (OPC) Private Limited
                        </Link>
                      {/* &nbsp; Design &amp; Developed By &nbsp;
                      <Link
                        className="text-warning"
                        to="https://dqotsolutions.com/"
                        target="_blank"
                      >
                        DQOT SOLUTIONS
                      </Link> */}
                    </p>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </div>
      </footer>
      {/* <section className="footer-bottom pt-5 pb-4">
        <Container>
          <Row>
            <Col className="text-center">
              <h4>Disclaimer</h4>
              <p>Dzire11 is not responsible for its users' actions regarding their participation. Users should check
                the rules according to their states regarding Fantasy sports.</p>
            </Col>
          </Row>
        </Container>
      </section> */}
    </>
  );
}

export default Footer;
